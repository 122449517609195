import React from "react";

export default function ObjectSelectedOptions({ option1, option2 }) {
  return (
    <>
      <div className="d-flex object-selected-options">
        <div className="text-capitalize p-8-16 border-100 me-1">{option1}</div>
        {option2 ? (
          <div className="text-capitalize p-8-16 border-100">{option2}</div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
