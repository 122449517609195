/* eslint-disable react/style-prop-object */
import React from "react";
import { Input } from "../Helpers/Input/Input";
import Button from "../Helpers/Button/Button";
import axios from "axios";
import qs from "qs";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { webSocketManager } from "../../Sockets/WebSocketManager";

export default function Login() {
  let navigate = useNavigate();
  const Login = async (values) => {
    let auth = qs.stringify(values);
    try {
      let response = await axios.post(
        "https://test.passenger-mea.com/api/session",
        auth,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          withCredentials: true,
        }
      );
      if (response) {
        let token = await axios.post(
          "https://test.passenger-mea.com/api/session/token",
          auth,
          {
            headers: {
              Authorization: `Basic ${btoa(
                `${values.email}:${values.password}`
              )}`,
            },
            withCredentials: true,
          }
        );
        if (token) {
          localStorage.setItem("token", String(token.data));
        }
        await axios.get("https://test.passenger-mea.com/api/session", {
          withCredentials: true,
        });
        webSocketManager.connect();
      }
      navigate("/dashboard");
    } catch (error) {
      console.log(error);
      console.log("Invalid Email or Password");
    }
  };
  let validSchema = Yup.object({
    email: Yup.string().required("Email is required").email("invalid email"),
    password: Yup.string().required("Password is required"),
  });
  let register = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validSchema,
    onSubmit: (values) => {
      Login(values);
    },
  });
  return (
    <>
      <div className="AuthLayout">
        <div className="flex-between w-100">
          <img src="assets/LoginLogo.svg" alt="" />
          <form onSubmit={register.handleSubmit}>
            <div className="Auth-Form">
              <Input
                title="Email"
                type="email"
                placeholder="Enter Your Email"
                onBlur={register.handleBlur}
                onChange={register.handleChange}
                formstyle={
                  register.errors.email && register.touched.email
                    ? "form-error"
                    : "mb-2"
                }
                id="email"
                name="email"
              />
              {register.errors.email && register.touched.email ? (
                <div className="validation">{register.errors.email}</div>
              ) : (
                ""
              )}
              <Input
                title="Password"
                type="password"
                placeholder="Enter Your Password"
                onBlur={register.handleBlur}
                onChange={register.handleChange}
                formstyle={
                  register.errors.password && register.touched.password
                    ? "form-error"
                    : "mb-2"
                }
                id="password"
                name="password"
              />
              {register.errors.password && register.touched.password ? (
                <div className="validation">{register.errors.password}</div>
              ) : (
                ""
              )}
              <div className="text-center">
                <Button
                  style="button btn-success p-4-10"
                  text="Login"
                  type="submit"
                  textstyle="mx-auto"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
