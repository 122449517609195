import React, { useContext } from "react";
import { popupcontext } from "../../../../context/Popupscontext";
import { VehiclePopup } from "../../Popup/PopupComponents";

export default function Display({ vehicleId }) {
  const { currentVehicleId, showDetails, hideDetails, isDeleting , isEditing } =
    useContext(popupcontext);

  const handleDisplayClick = () => {
    if (isDeleting === false) {
      // Check if delete action is not active
      showDetails(vehicleId);
    }
  };

  return (
    <>
      <img
        role="button"
        src="/assets/Eye.svg"
        alt="show"
        onClick={handleDisplayClick}
      />
      {currentVehicleId === vehicleId &&
        !isDeleting && !isEditing &&( // Prevent display popup if deleting
          <VehiclePopup vehicleId={currentVehicleId} onClose={hideDetails} />
        )}
    </>
  );
}
