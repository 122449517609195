import React from "react";
import { NavLink } from "react-router-dom";

export default function DropSelection({ selectitem, path }) {
  return (
    <>
      <div role="button">
        <NavLink className="dropdown-select" to={path}>
          <div className="ms-3 py-2 select-text">{selectitem}</div>
        </NavLink>
      </div>
    </>
  );
}
