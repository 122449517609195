import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const VehicleApi = createApi({
  reducerPath: "vehicle",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://test.passenger-mea.com/backend/",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllVehicles: builder.query({
      query: (page) =>
        `/vehicles?page=${page}&limit=5&sortBy=id&sortOrder=desc`,
    }),
    getSpecificVehicle: builder.query({
      query: (id) => `/vehicles/${id}`,
    }),
    getSpecificVehicleImei: builder.query({
      query: (imei) => `/vehicles/imei/${imei}`,
    }),
    addVehicles: builder.mutation({
      query: (val) => ({
        url: "/vehicles/register",
        method: "POST",
        body: val,
      }),
    }),
    editVehicle: builder.mutation({
      query: ({ id, val }) => ({
        url: `vehicles/${id}`,
        method: "PUT",
        body: val,
      }),
    }),
    deleteVehicle: builder.mutation({
      query: (val) => ({
        url: `vehicles/${val}`,
        method: "DELETE",
        body: val,
      }),
    }),
  }),
});

export const {
  useGetAllVehiclesQuery,
  useGetSpecificVehicleQuery,
  useGetSpecificVehicleImeiQuery,
  useLazyGetAllVehiclesQuery,
  useLazyGetSpecificVehicleQuery,
  useAddVehiclesMutation,
  useEditVehicleMutation,
  useDeleteVehicleMutation,
} = VehicleApi;
