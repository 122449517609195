/* eslint-disable react/style-prop-object */
import React, { useContext, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Close from "../CloseBtn/Close";
import { popupcontext } from "../../../context/Popupscontext";
import Button from "../Button/Button";
import {
  useDeleteVehicleMutation,
  useGetAllVehiclesQuery,
} from "../../../Redux/service/Vehicles/Vehicles";
import { useDispatch } from "react-redux";
import { addVehicles } from "../../../Redux/service/Vehicles/VehiclesSlice";
import { toast } from "react-toastify";
import { filtrationcontext } from "../../../context/Filtercontext";
import {
  useDeleteDriverMutation,
  useGetAllDriversQuery,
} from "../../../Redux/service/Drivers/Drivers";
import { addDrivers } from "../../../Redux/service/Drivers/DriversSlice";

export default function Delete({ vehicleId, DriverId, handleDeleteClick }) {
  const {
    currentVehicleId,
    currentDriverId,
    handleClose,
    setDeleted,
    isDeleting,
    isEditing,
  } = useContext(popupcontext);
  const { limit } = useContext(filtrationcontext);
  const dispatch = useDispatch();
  let [deleteVehicle] = useDeleteVehicleMutation();
  let [deleteDriver] = useDeleteDriverMutation();
  let currentPage = sessionStorage.getItem("page");
  const { data: vehiclesData, refetch } = useGetAllVehiclesQuery(
    { page: Number(currentPage), limit: limit },
    {
      skip: !currentPage || !limit,
    }
  );
  const { data: driversData, refetch: DriverRefetch } = useGetAllDriversQuery(
    { page: Number(currentPage), limit: limit },
    {
      skip: !currentPage || !limit,
    }
  );
  useEffect(() => {
    if (currentPage && limit) {
      refetch();
      DriverRefetch();
    }
  }, [DriverRefetch, currentPage, limit, refetch]);

  const handleRemoveVehicle = async () => {
    try {
      await deleteVehicle(vehicleId).unwrap();
      if (vehiclesData?.vehicles.length === 1 && currentPage > 1) {
        currentPage -= 1;
        sessionStorage.setItem("page", currentPage);
      }
      setDeleted(true);
      handleClose("deleting");
      toast.success("Vehicle Deleted Successfully", { autoClose: 200 });
      await refetch();
      if (vehiclesData) {
        dispatch(addVehicles(vehiclesData.vehicles));
      }
    } catch (error) {
      toast.error(`Error: ${error}`, { autoClose: 300 });
    }
  };

  const handleRemoveDriver = async () => {
    try {
      await deleteDriver(DriverId)
        .unwrap()
        .catch((err) => console.log(err));
      if (driversData?.drivers.length === 1 && currentPage > 1) {
        currentPage -= 1;
        sessionStorage.setItem("page", currentPage);
      }
      setDeleted(true);
      handleClose("deleting");
      toast.success("Driver Deleted Succesfully", { autoClose: 200 });
      await DriverRefetch();
      if (driversData) {
        dispatch(addDrivers(driversData.drivers));
      }
    } catch (error) {
      toast.error(`${error}`, { autoClose: 300 });
    }
  };

  return (
    <>
      <img
        role="button"
        src="/assets/Delete.svg"
        alt="delete"
        onClick={handleDeleteClick}
      />

      {((vehicleId &&
        currentVehicleId === vehicleId &&
        isDeleting &&
        !isEditing) ||
        (DriverId &&
          currentDriverId === DriverId &&
          isDeleting &&
          !isEditing)) && (
        <Modal show={isDeleting} centered>
          <Modal.Header>
            <div className="flex-between w-100">
              <Modal.Title>Delete</Modal.Title>
              <Close
                style="close-32 close"
                close={() => handleClose("deleting")}
                img="Close"
              />
            </div>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete?</Modal.Body>
          <Modal.Footer>
            <Button
              style="button p-6-12 btn-default"
              onClick={() => handleClose("deleting")}
              text="Cancel"
            />
            <Button
              style="button p-6-12 btn-danger"
              text="Delete"
              onClick={vehicleId ? handleRemoveVehicle : handleRemoveDriver}
            />
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}
