import React from "react";
import {
  Input,
  InputCountriesGroup,
  InputFuelGroup,
  InputVehicleGroup,
  Sensors,
} from "../../Helpers/Input/Input";
import FuelSupport from "./FuelSupport/FuelSupport";

export default function VehicleInfo({ addData }) {
  return (
    <div className="vehicleinfo mb-24">
      <p className="fs-16 fw-700 brand-700 text-uppercase">vehicle info</p>
      <div className="flex-between">
        <div className="vehicle-input">
          <Input
            title="Vehicle name"
            type="text"
            placeholder="Name"
            id="name"
            name="name"
            vehicleformstyle="vehicle-form"
            onBlur={addData.handleBlur}
            onChange={addData.handleChange}
            errors={addData.errors.name}
            touched={addData.touched.name}
            value={addData.values.name || ""}
          />
        </div>
        <div className="vehicle-input">
          <Input
            title="Plate Number"
            type="text"
            placeholder="License plate"
            id="plateNumber"
            name="plateNumber"
            vehicleformstyle="vehicle-form"
            onBlur={addData.handleBlur}
            onChange={addData.handleChange}
            errors={addData.errors.plateNumber}
            touched={addData.touched.plateNumber}
            value={addData.values.plateNumber || ""}
          />
        </div>
      </div>
      <div className="flex-between">
        <div className="vehicle-input">
          <Input
            title="VIN"
            type="text"
            placeholder="VIN"
            id="vin"
            name="vin"
            vehicleformstyle="vehicle-form"
            onBlur={addData.handleBlur}
            onChange={addData.handleChange}
            value={addData.values.vin || ""}
          />
        </div>
        <div className="vehicle-input">
          <Input
            title="Code"
            type="text"
            placeholder="Code"
            id="code"
            name="code"
            vehicleformstyle="vehicle-form"
            onBlur={addData.handleBlur}
            onChange={addData.handleChange}
            value={addData.values.code || ""}
          />
        </div>
      </div>
      <div className="flex-between">
        <div className="vehicle-input">
          <InputVehicleGroup
            id="vehicleType"
            name="vehicleType"
            vehicleformstyle="vehicle-form"
            onBlur={addData.handleBlur}
            onChange={addData.handleChange}
            errors={addData.errors.vehicleType}
            touched={addData.touched.vehicleType}
            value={addData.values.vehicleType || ""}
          />
        </div>
        <div className="vehicle-input">
          <Input
            title="Registration number"
            type="text"
            placeholder="SubType"
            id="registrationNumber"
            name="registrationNumber"
            vehicleformstyle="vehicle-form"
            onBlur={addData.handleBlur}
            onChange={addData.handleChange}
            value={addData.values.registrationNumber || ""}
          />
        </div>
      </div>
      <div className="flex-between">
        <div className="vehicle-input">
          <Input
            title="Model"
            type="text"
            placeholder="Model"
            id="model"
            name="model"
            vehicleformstyle="vehicle-form"
            onBlur={addData.handleBlur}
            onChange={addData.handleChange}
            value={addData.values.model || ""}
          />
        </div>
        <div className="vehicle-input">
          <Input
            title="Brand"
            type="text"
            placeholder="Vehicle Brand"
            id="brand"
            name="brand"
            vehicleformstyle="vehicle-form"
            onBlur={addData.handleBlur}
            onChange={addData.handleChange}
            value={addData.values.brand || ""}
          />
        </div>
      </div>
      <div className="flex-between">
        <div className="vehicle-input">
          <Input
            title="Color"
            type="text"
            id="color"
            name="color"
            placeholder="Vehicle Color"
            vehicleformstyle="vehicle-form"
            onBlur={addData.handleBlur}
            onChange={addData.handleChange}
            errors={addData.errors.color}
            touched={addData.touched.color}
            value={addData.values.color || ""}
          />
        </div>
        <div className="vehicle-input">
          <Input
            title="Year"
            type="text"
            id="year"
            name="year"
            placeholder="Year"
            vehicleformstyle="vehicle-form"
            onBlur={addData.handleBlur}
            onChange={addData.handleChange}
            value={addData.values.year || ""}
          />
        </div>
      </div>
      <div className="flex-between">
        <div className="vehicle-input">
          <InputCountriesGroup
            id="make"
            name="make"
            vehicleformstyle="vehicle-form"
            onBlur={addData.handleBlur}
            onChange={addData.handleChange}
            value={addData.values.make || ""}
          />
        </div>
        <div className="vehicle-input">
          <InputFuelGroup
            id="fuelType"
            name="fuelType"
            vehicleformstyle="vehicle-form"
            onBlur={addData.handleBlur}
            onChange={addData.handleChange}
            value={addData.values.fuelType || ""}
          />
        </div>
      </div>
      <div className="flex-between">
        <div className="vehicle-input">
          <Input
            title="Fuel Consumption"
            type="text"
            placeholder="Liter/100 KM"
            id="fuelConsumption"
            name="fuelConsumption"
            vehicleformstyle="vehicle-form"
            onBlur={addData.handleBlur}
            onChange={addData.handleChange}
            value={addData.values.fuelConsumption || ""}
          />
        </div>
        <div className="vehicle-input">
          <Input
            title="Fuel Cost"
            type="text"
            placeholder="0.0 SAR"
            id="fuelCost"
            name="fuelCost"
            vehicleformstyle="vehicle-form"
            onBlur={addData.handleBlur}
            onChange={addData.handleChange}
            value={addData.values.fuelCost || ""}
          />
        </div>
      </div>

      <div className="w-50">
        <Sensors
          vehicleInf={true}
          title="Fuel"
          id="fuelSupport"
          name="fuelSupport"
          onBlur={addData.handleBlur}
          onChange={addData.handleChange}
          value={addData.values.fuelSupport || ""}
        />
      </div>
      {addData.values.fuelSupport && <FuelSupport addData={addData} />}
    </div>
  );
}
