import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import Mainlayout from "./components/Layouts/Mainlayout";
import Sidebarcontext from "./context/Sidebarcontext";
import Dashboard from "./components/Dashboard/Dashboard";
import Random from "./components/Random";
import Vehicles from "./components/Vehicles/Vehicles";
import Drivers from "./components/Drivers/Drivers";
import PopupContext from "./context/Popupscontext";
import LiveTracking from "./components/LiveTracking/LiveTracking";
import Authlayout from "./components/Layouts/Authlayout";
import Login from "./components/Login/Login";
import ProtectedRoutes from "./ProtectedRoutes/ProtectedRoutes";
import Filtercontext from "./context/Filtercontext";
import { ToastContainer } from "react-toastify";
import PlayBack from "./components/HistoryPlayBack/PlayBack";
import Soon from "./components/Soon";

function App() {
  let routes = createBrowserRouter([
    {
      path: "/",
      element: <Mainlayout />,
      children: [
        {
          index: true,
          element: (
            <ProtectedRoutes>
              <Dashboard />
            </ProtectedRoutes>
          ),
        },
        {
          path: "dashboard",
          element: (
            <ProtectedRoutes>
              <Dashboard />
            </ProtectedRoutes>
          ),
        },
        {
          path: "livetracking",
          element: (
            <ProtectedRoutes>
              <LiveTracking />
            </ProtectedRoutes>
          ),
        },
        {
          path: "historyplayback/:id",
          element: (
            <ProtectedRoutes>
              <PlayBack />
            </ProtectedRoutes>
          ),
        },
        {
          path: "fleetmanagement",
          children: [
            {
              path: "vehicles",
              element: (
                <ProtectedRoutes>
                  <Vehicles />
                </ProtectedRoutes>
              ),
            },
            {
              path: "drivers",
              element: (
                <ProtectedRoutes>
                  <Drivers />
                </ProtectedRoutes>
              ),
            },
          ],
        },
        {
          path: "*",
          element: (
            <ProtectedRoutes>
              <Soon />
            </ProtectedRoutes>
          ),
        },
      ],
    },
    {
      path: "/",
      element: <Authlayout />,
      children: [{ path: "signin", element: <Login /> }],
    },
  ]);
  return (
    <>
      <Sidebarcontext>
        <PopupContext>
          <Filtercontext>
            <RouterProvider router={routes} />
          </Filtercontext>
        </PopupContext>
      </Sidebarcontext>{" "}
      <ToastContainer theme="colored" />
    </>
  );
}

export default App;
