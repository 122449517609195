import React from "react";
import { NavLink } from "react-router-dom";

export default function SelectionItem({ path, name, icon }) {
  return (
    <>
      <NavLink to={path} className="select-item flex-center">
        <div className="select-icons">
          <img className="icons" src={`/assets/dark/${icon}.svg`} alt={name} />
        </div>
        <p className="select-text">{name}</p>
      </NavLink>
    </>
  );
}
