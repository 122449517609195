import React, { useContext, useState } from "react";
import ObjectTableRows from "./ObjectTableRows";
import { useGetAllUsersQuery } from "../../../../Redux/service/Users";
import { filtrationcontext } from "../../../../context/Filtercontext";
import Loader from "../../../Helpers/Loader/Loader";

export default function ObjectTableBody() {
  const [openDropdowns, setOpenDropdowns] = useState([]);
  const { filter, searchQuery, parentCheck, childCheck } =
    useContext(filtrationcontext);

  const toggleDropdown = (userId) => {
    setOpenDropdowns((prevOpenDropdowns) =>
      prevOpenDropdowns.includes(userId)
        ? prevOpenDropdowns.filter((id) => id !== userId)
        : [...prevOpenDropdowns, userId]
    );
  };

  const { data, isLoading } = useGetAllUsersQuery();

  const filteredData = data?.filter((user) => {
    const matchesFilter =
      childCheck.includes(user.id) || parentCheck.includes(user.id);
    const matchesSearch = searchQuery
      ? user.name.toLowerCase().includes(searchQuery.toLowerCase())
      : true;
    if (childCheck.length === 0 && parentCheck.length === 0)
      return user.administrator === true;
    return (
      (filter ? matchesFilter : user.administrator === true) && matchesSearch
    );
  });

  if (isLoading) return <Loader />;

  return (
    <>
      {filteredData?.map((user) => (
        <div key={user.id}>
          <div
            className="object-body-title"
            role="button"
            onClick={() => toggleDropdown(user.id)}
          >
            <div className="d-flex">
              <div className="table-checkbox by-15">
                <input type="checkbox" onChange={() => {}} name="" id="" />
              </div>
              <div
                className="fs-14 fw-400 neutral-500 object-table-element"
                role="button"
              >
                <div className={user.administrator === true ? `fw-900` : ""}>
                  {user.name}
                </div>
              </div>
            </div>
            <div className={user.administrator === true ? "d-none" : ""}>
              <img
                src={
                  openDropdowns.includes(user.id)
                    ? "/assets/Uparrow.svg"
                    : "/assets/Downarrow.svg"
                }
                alt="arrow"
              />
            </div>
          </div>
          {openDropdowns.includes(user.id) || user.administrator === true ? (
            <ObjectTableRows userid={user.id} />
          ) : null}
        </div>
      ))}
    </>
  );
}
