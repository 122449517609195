import React from "react";
import { Input } from "../../../Helpers/Input/Input";

export default function GeofenceBody({ AddGeo }) {
  return (
    <div className="p-16">
      <form>
        <Input
          title="Name"
          type="text"
          placeholder="Geofence Name"
          id="name"
          name="name"
          formstyle={
            AddGeo.errors.name && AddGeo.touched.name ? "form-error" : "mb-4"
          }
          onBlur={AddGeo.handleBlur}
          onChange={AddGeo.handleChange}
        />
        {AddGeo.errors.name && AddGeo.touched.name ? (
          <div className="validation">{AddGeo.errors.name}</div>
        ) : (
          ""
        )}
        <Input
          title="Description"
          type="text"
          placeholder="Geofence Description"
          id="description"
          name="description"
          onBlur={AddGeo.handleBlur}
          onChange={AddGeo.handleChange}
        />
      </form>
    </div>
  );
}
