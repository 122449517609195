import React from "react";
import ObjectSelectedRows from "../SelectedBody/ObjectSelectedRows";
import { formatDate } from "../../../../JsHelpers/DateFormat";

export default function ObjectSelectedStatus({ position, device }) {
  console.log("Details", device);
  console.log("Position", position);
  const { formattedDate, formattedTime } = formatDate(device?.lastUpdate);
  return (
    <>
      <div className="object-working-status">
        <div className="object-status-details">
          <ObjectSelectedRows
            title="GPS staus"
            item={`${
              device?.status === "online" ? "Available" : "UnAvailable"
            }`}
          />
        </div>
        <div className="object-status-details">
          <ObjectSelectedRows
            title="GSM strength"
            item={`${Math.round(position?.accuracy * 100)} %`}
          />
        </div>
        <div className="object-status-details">
          <ObjectSelectedRows
            title="connectivity"
            item={`${
              device?.status === "online" ? "Connected" : "Disconnected"
            }`}
          />
        </div>
        <div className="object-status-details">
          <ObjectSelectedRows title="latitude" item={`${position.latitude}`} />
        </div>
        <div className="object-status-details">
          <ObjectSelectedRows
            title="longitude"
            item={`${position.longitude}`}
          />
        </div>
        <div className="object-status-details">
          <ObjectSelectedRows title="address" item="Get current address" />
        </div>
        <div className="object-status-details">
          <ObjectSelectedRows
            title="last update"
            item={`${formattedDate + " " + formattedTime}`}
          />
        </div>
      </div>
    </>
  );
}
