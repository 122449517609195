/* eslint-disable react/style-prop-object */
import React, { useContext } from "react";
import { Modal } from "react-bootstrap";
import Close from "../CloseBtn/Close";
import { popupcontext } from "../../../context/Popupscontext";
import Button from "../Button/Button";
import { useDeleteVehicleMutation } from "../../../Redux/service/Vehicles/Vehicles";

export default function Delete({ vehicleId, onClick, onHide }) {
  const { remove, currentVehicleId, handleCloseRemove, setDeleted } =
    useContext(popupcontext);
  let [deleteVehicle, { data, isLoading }] = useDeleteVehicleMutation();
  const handleRemoveVehicle = async () => {
    await deleteVehicle(vehicleId).unwrap();
    setDeleted(true);
  };
  const handleDeleteClick = () => {
    onClick(vehicleId);
  };

  return (
    <>
      <img
        role="button"
        src="/assets/Delete.svg"
        alt="delete"
        onClick={handleDeleteClick}
      />
      {currentVehicleId === vehicleId && (
        <Modal show={remove} onHide={onHide} centered>
          <Modal.Header>
            <div className="flex-between w-100">
              <Modal.Title>Delete {vehicleId}</Modal.Title>
              <Close
                style="close-32 close"
                close={handleCloseRemove}
                img="Close"
              />
            </div>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete?</Modal.Body>
          <Modal.Footer>
            <Button
              style="button p-6-12 btn-default"
              onClick={handleCloseRemove}
              text="Cancel"
            />
            <Button
              style="button p-6-12 btn-danger"
              text="Delete"
              onClick={handleRemoveVehicle}
            />
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}
