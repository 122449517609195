import React from "react";
import ReactPaginate from "react-paginate";

export default function Pagination({ handlePageClick }) {
  return (
    <>
      <ReactPaginate
        previousLabel={"previous"}
        nextLabel={"next"}
        breakLabel={"..."}
        initialPage={0}
        pageCount={25}
        marginPagesDisplayed={2}
        pageRangeDisplayed={3}
        containerClassName={"pagination"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        activeClassName={"active"}
        onPageChange={handlePageClick}
      />
    </>
  );
}
