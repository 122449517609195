import React from "react";
import ObjectSelectedRows from "../SelectedBody/ObjectSelectedRows";
import { useGetAllUsersQuery } from "../../../../Redux/service/Users/Users";

export default function ObjectSelectedDetails({ details }) {
  const { data } = useGetAllUsersQuery();

  const selectedUser = data?.find((user) => user.id === details?.parent)
  return (
    <>
    
      <div className="object-selected-details">
        <div className="flex-center mb-2">
          <img src="/assets/Car.svg" alt="" />
          <div className="ms-3">
            <div className="fs-16 fw-600 neutral-700">{details?.name}</div>
            <div className="fs-14 fw-400 neutral-500">{details?.brand}</div>
          </div>
        </div>
        <ObjectSelectedRows title="car number" item={details?.plateNumber} />
        <ObjectSelectedRows title="sim number" item={details?.simNumber} />
        <ObjectSelectedRows title="device ID" item={details?.imei} />
        <ObjectSelectedRows
          title="car activity"
          item={details?.archived ? "Active" : "Archived"}
        />
        
        <ObjectSelectedRows title="related user" item={selectedUser?.name} />
        <ObjectSelectedRows
          title="assigned driver"
          item={details?.driver?.name ? details?.driver?.name : "Assign Driver"}
        />
      </div>
    </>
  );
}
