/* eslint-disable react/style-prop-object */
import React, { useContext, useState } from "react";
import { sidebarcontext } from "../../context/Sidebarcontext";
import Searchbar from "../Helpers/Searchbar/Searchbar";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { webSocketManager } from "../../Sockets/WebSocketManager";

export default function Header() {
  let { isSideOpen } = useContext(sidebarcontext);
  const [options, setOptions] = useState(false);
  let navigate = useNavigate();

  const handleLogout = async () => {
    await axios.delete("https://test.passenger-mea.com/api/session", {
      headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
    });
    webSocketManager.disconnect();
    sessionStorage.setItem("token", "");
    navigate("/signin");   
     window.location.reload();

  };
  return (
    <div className={isSideOpen ? "header header-max" : "header header-min"}>
      <div className="flex-between w-100">
        <Searchbar
          style="search input-group"
          path="/assets/dark/search.svg"
          placeholder="Search anything"
        />
        <div className="header-tools flex-between">
          <div className="notify border-16">
            <img src="/assets/dark/notifications.svg" alt="notification" />
            <div className="notify-count"></div>
          </div>
          <div className="border-16">
            <img src="/assets/dark/expand.svg" alt="expand" />
          </div>
          <div className="header-username" onClick={() => setOptions(!options)}>
            <p>FM</p>
            {options && (
              <div className="header-dropdown">
                <div className=" header-dropdown-option" onClick={handleLogout}>
                  Logout
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}