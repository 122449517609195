import React, { useContext, useState } from "react";
import ObjectSelected from "../../ObjectSelected/ObjectSelected";
import { popupcontext } from "../../../../context/Popupscontext";
import { useGetUsersDeviceQuery } from "../../../../Redux/service/Devices";
import { formatDate } from "../../../../JsHelpers/DateFormat";
import { useMap } from "react-leaflet";
import { useSelector } from "react-redux";
import Loader from "../../../Helpers/Loader/Loader";

export default function ObjectTableRows({ userid }) {
  let { handleShowObject, object, objectselect, setObjectselect } =
    useContext(popupcontext);
  const [selectedId, setSelectedId] = useState(null);
  const [imei, setImei] = useState(null);
  let { data, isLoading } = useGetUsersDeviceQuery(userid);
  const positions = useSelector((state) => state.webSocket.positions);
  const devices = useSelector((state) => state.webSocket.devices);
  const map = useMap();

  const getFormattedDate = (serverTime) => formatDate(serverTime);

  const handleRowClick = (id, lat, lon, imei) => {
    setObjectselect(id === selectedId ? !objectselect : true);
    setSelectedId(id);
    handleShowObject();
    const targetCoordinates = lat && lon ? [lat, lon] : [33, 30];
    map.flyTo(targetCoordinates, 13);
    setImei(imei);
  };

  if (isLoading) return <Loader />;

  return (
    <>
      {data?.map((i) => {
        const position = positions.find(
          (position) => position.deviceId === i.id
        );
        const device = devices.find((device) => device.id === i.id);
        const speed = position ? `${Math.round(position.speed)}` : 0;
        const { formattedDate, formattedTime } = getFormattedDate(
          position?.serverTime
        );
        return (
          <div key={i.id}>
            <div
              className="object-table object-table-row"
              onClick={() => {
                handleRowClick(
                  i.id,
                  position?.latitude ?? 24.7136,
                  position?.longitude ?? 46.6753,
                  i?.uniqueId
                );
              }}
            >
              <div className="table-checkbox">
                <input
                  type="checkbox"
                  checked={
                    (object && i.id === selectedId) ||
                    (object && objectselect && i.id === selectedId)
                  }
                  onChange={() => {}}
                  name=""
                  id=""
                />
              </div>
              <div className="fs-14 fw-400 object-table-element w-25">
                {i.name}
              </div>
              <div className="fs-14 fw-400 object-table-element">
                {device?.status === "online" &&
                position?.attributes.motion !== false
                  ? speed
                  : 0}{" "}
                Km/h
              </div>
              <div className="fs-14 fw-400 object-table-element">
                <p className="fs-14 fw-400 object-table-element m-0">
                  {formattedDate}
                </p>
                <p className="fs-14 fw-400 object-table-element m-0">
                  {formattedTime}
                </p>
              </div>
              <div className="object-table-element">
                <div
                  className={
                    device?.status === "online"
                      ? "status-online"
                      : "status-offline"
                  }
                ></div>
              </div>
            </div>
            {object && imei === i?.uniqueId ? (
              <ObjectSelected imei={imei} device={i} position={position} />
            ) : (
              ""
            )}
          </div>
        );
      })}
    </>
  );
}
