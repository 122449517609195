import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const UsersApi = createApi({
  reducerPath: "Users",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://test.passenger-mea.com/api",
    prepareHeaders: (headers) => {
      const token = sessionStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllUsers: builder.query({
      query: () => "/users",
    }),
    getChildUsers: builder.query({
      query: (userId) => `/users?userId=${userId}`,
    }),
    getSpecificUser: builder.query({
      query: (userId) => `/users/${userId}`,
    }),
  }),
});

export const {
  useGetAllUsersQuery,
  useGetChildUsersQuery,
  useGetSpecificUserQuery,
  useLazyGetChildUsersQuery,
  useLazyGetAllUsersQuery,
  useLazyGetSpecificUserQuery,
} = UsersApi;
