import React from "react";
import { useListAllDriversQuery } from "../../../Redux/service/Drivers/Drivers";
import { useGetAllUsersQuery } from "../../../Redux/service/Users/Users";
import { useGetSpecificCustomUserQuery } from "../../../Redux/service/Users/CustomUsers";
 
export function Input({
  title,
  type,
  placeholder,
  onBlur,
  onChange,
  id,
  name,
  formstyle,
  value,
  errors,
  touched,
  disabled,
  min,
}) {
  const FormError = errors && touched ? "form-error" : "";

  return (
    <>
      <div
        className={`position-relative vehicle-input ${formstyle} ${FormError}`}
      >
        <label className="form-label">{title}</label>
        <input
          className={`form-control`}
          type={type}
          placeholder={placeholder}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          id={id}
          name={name}
          disabled={disabled}
          min={min}
        />
        <div>
          {errors && touched ? <div className="validation">{errors}</div> : ""}
        </div>
      </div>
    </>
  );
}

export function InputVehicleGroup({
  id,
  name,
  onChange,
  onBlur,
  errors,
  touched,
  formstyle,
  value,
}) {
  const FormError = errors && touched ? "form-error" : "";

  return (
    <>
      <div
        className={`position-relative vehicle-input ${formstyle} ${FormError} `}
      >
        <label className="form-label">Vehicle type</label>
        <select
          id={id}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          value={value || ""}
          className="form-select"
        >
          <option value="" disabled>
            Select Vehicle Type
          </option>
          <optgroup className="input-group" label="Commercial Vehicles">
            <option value="Delivery Trucks">Delivery Trucks</option>
            <option value="Cargo Vans">Cargo Vans</option>
            <option value="Utility Trucks">Utility Trucks</option>
          </optgroup>
          <optgroup label="Passenger Vehicles">
            <option value="Sedans">Sedans</option>
            <option value="Suvs">Suvs</option>
            <option value="Vans">Vans</option>
          </optgroup>
          <optgroup label="Specialized Vehicles">
            <option value="Ambulances">Ambulances</option>
            <option value="Police Cars">Police Cars</option>
            <option value="Fire Trucks">Fire Trucks</option>
            <option value="Constructions Vehicles">
              Construction Vehicles
            </option>
          </optgroup>
          <optgroup label="Public Transportation Vehicles">
            <option value="Buses">Buses</option>
            <option value="Shuttles">Shuttles</option>
          </optgroup>
          <optgroup label="Heavy-Duty Vehicles">
            <option value="Trucks">Trucks</option>
            <option value="Trailers">Trailers</option>
          </optgroup>
          <optgroup label="Special Purpose Vehicles">
            <option value="Refrigerated Trucks">Refrigerated Trucks</option>
            <option value="Tanker Trucks">Tanker Trucks</option>
            <option value="Tow Trucks">Tow Trucks</option>
          </optgroup>
          <optgroup label="Construction Off-Road Vehicles">
            <option value="Excavators">Excavators</option>
            <option value="Bulldozers">Bulldozers</option>
            <option value="Off-Road Vehicles">Off-Road Vehicles</option>
          </optgroup>
          <optgroup label="Electric Vehicles (EVs)">
            <option value="Electric Cars">Electric Cars</option>
            <option value="Electric Trucks">Electric Trucks</option>
            <option value="Electric Vans">Electric Vans</option>
          </optgroup>
        </select>
        {errors && touched ? <div className="validation">{errors}</div> : ""}
      </div>
    </>
  );
}

export function InputCountriesGroup({
  id,
  name,
  onChange,
  onBlur,
  errors,
  touched,
  formstyle,
  value,
}) {
  const FormError = errors && touched ? "form-error" : "";

  return (
    <>
      <div
        className={`position-relative vehicle-input ${formstyle} ${FormError} `}
      >
        <label className="form-label">Manfacture country</label>
        <select
          id={id}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          value={value || ""}
          className="form-select"
        >
          <option value="" disabled>
            Select country
          </option>
          <option value="argentina">Argentina</option>
          <option value="australia">Australia</option>
          <option value="brazil">Brazil</option>
          <option value="canada">Canada</option>
          <option value="china">China</option>
          <option value="france">France</option>
          <option value="germany">Germany</option>
          <option value="india">India</option>
          <option value="italy">Italy</option>
          <option value="japan">Japan</option>
          <option value="korea-south">South Korea</option>
          <option value="mexico">Mexico</option>
          <option value="russia">Russia</option>
          <option value="spain">Spain</option>
          <option value="taiwan">Taiwan</option>
          <option value="uk">United Kingdom</option>
          <option value="usa">USA</option>
          <option value="turkey">Turkey</option>
          <option value="belgium">Belgium</option>
          <option value="netherlands">Netherlands</option>
          <option value="portugal">Portugal</option>
          <option value="sweden">Sweden</option>
        </select>
        {errors && touched ? <div className="validation">{errors}</div> : ""}
      </div>
    </>
  );
}

export function InputFuelGroup({
  id,
  name,
  onChange,
  onBlur,
  errors,
  touched,
  formstyle,
  value,
}) {
  const FormError = errors && touched ? "form-error" : "";

  return (
    <>
      <div
        className={`position-relative vehicle-input ${formstyle} ${FormError} `}
      >
        <label className="form-label">Fuel Type</label>
        <select
          className="form-select"
          id={id}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          value={value || ""}
        >
          <option value="" disabled>
            Select Fuel Type
          </option>
          <option value="Gas">Gas</option>
          <option value="Diesel">Diesel</option>
          <option value="Sollar">Sollar</option>
        </select>
        {errors && touched ? <div className="validation">{errors}</div> : ""}
      </div>
    </>
  );
}

export function InputParentGroup({
  id,
  name,
  onChange,
  onBlur,
  errors,
  touched,
  value,
  formstyle,
}) {
  const FormError = errors && touched ? "form-error" : "";
  const { data } = useGetSpecificCustomUserQuery();
  const selectedUser = data?.find((user) => user.id === value);
  return (
    <>
      <div
        className={`position-relative vehicle-input ${formstyle} ${FormError} `}
      >
        <label className="form-label">Parent</label>
        <select
          id={id}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          value={value !== "" ? selectedUser?.id : ""}
          className="form-select"
        >
          <option value="" disabled>
            Select Parent
          </option>
          {data?.map((user) => (
            <option key={user.user.id} value={user.user.id}>
              {user.user.name}
            </option>
          ))}
        </select>
        {errors && touched ? <div className="validation">{errors}</div> : ""}
      </div>
    </>
  );
}
export function InputDriversGroup({
  id,
  name,
  onChange,
  onBlur,
  errors,
  touched,
  value,
  formstyle,
}) {
  const FormError = errors && touched ? "form-error" : "";
  const { data } = useListAllDriversQuery();
  const selectedDriver = data?.find((driver) => driver.id === value);
  console.log("Selected ", selectedDriver);
  console.log("Value ", value);
  return (
    <>
      <div
        className={`position-relative vehicle-input ${formstyle} ${FormError} `}
      >
        <label className="form-label">Driver</label>
        <select
          id={id}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          className="form-select"
          value={value !== "" ? selectedDriver?.id : ""}
        >
          <option value="" disabled>
            Select Driver
          </option>
          {data?.map((driver) => (
            <option key={driver.id} value={driver.id}>
              {driver.name}
            </option>
          ))}
        </select>
        {errors && touched ? <div className="validation">{errors}</div> : ""}
      </div>
    </>
  );
}
export function Switch({ id, name, onBlur, onChange, value }) {
  return (
    <>
      <label className={`switch`}>
        <input
          className="form-control"
          type="checkbox"
          id={id}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          checked={value ? true : false}
          autoFocus
        />
        <span className="slider round"></span>
      </label>
    </>
  );
}

export function Sensors({
  title,
  vehicleInf,
  id,
  name,
  onBlur,
  onChange,
  value,
}) {
  return (
    <>
      <div className={`sensors mb-16`}>
        <div className="flex-between mb-8">
          <div className="fs-16 fw-500 neutral-500 text-capitalize">
            {vehicleInf ? `${title} support` : `${title}  sensor support`}
          </div>
          <div className="y-25">
            <Switch
              id={id}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
            />
          </div>
        </div>
        <div className={vehicleInf ? "" : "H-line-2"}></div>
      </div>
    </>
  );
}

export function PaginationCounter({ onBlur, onChange, value }) {
  return (
    <div>
      <select
        className="form-select"
        id="paginationcounter"
        name="paginationcounter"
        onChange={onChange}
      >
       
        <option value={10}>10</option>
        <option value={20}>20</option>
        <option value={30}>30</option>
        <option value={40}>40</option>
        <option value={50}>50</option>
        <option value={60}>60</option>
        <option value={70}>70</option>
        <option value={80}>80</option>
        <option value={90}>90</option>
        <option value={100}>100</option>
      </select>
    </div>
  );
}
