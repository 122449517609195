// src/service/webSocketSlice.js
import { createSlice } from "@reduxjs/toolkit";

export const webSocketSlice = createSlice({
  name: "webSocket",
  initialState: {
    data: null,
    positions:[],
    devices:[]
  },
  reducers: {
    setWebSocketData: (state, action) => {
      state.data = action.payload;
    },
    setPositions:(state,action)=>{
      state.positions = action.payload
    },
    setDevices:(state,action)=>{
      state.devices = action.payload
    }
  },
});

export const { setWebSocketData , setPositions , setDevices} = webSocketSlice.actions;

export default webSocketSlice.reducer;
