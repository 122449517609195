/* eslint-disable react/style-prop-object */
import React, { useContext } from "react";
import SecondHeader from "../Header/SecondHeader";
import VehicleHead from "./VehiclesParts/VehicleHead";
import { popupcontext } from "../../context/Popupscontext";
import { VehicleForm } from "../Helpers/Form/FormComponents";
import { Helmet } from "react-helmet";
import Pagination from "../Helpers/Pagination/Pagination";
import { useLazyGetAllVehiclesQuery } from "../../Redux/service/Vehicles/Vehicles";
import Table from "../Helpers/Table/Table";
import Loader from "../Helpers/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { addVehicles } from "../../Redux/service/Vehicles/VehiclesSlice";

export default function Vehicles() {
  let { handleShow, setEdited } = useContext(popupcontext);
  const dispatch = useDispatch();
  const HeadField = [
    "Name",
    "Plate Number",
    "Status",
    "IMEI",
    "Last update",
    "Condition",
    "Actions",
  ];
  let [triggerGetAllVehicles, { isLoading }] = useLazyGetAllVehiclesQuery();
  //const [vehicles, setVehicles] = useState([]);

  const handlePageClick = async (data) => {
    let selection = data.selected + 1;
    let VehicleData = await triggerGetAllVehicles(selection, true);
    dispatch(addVehicles(VehicleData.data?.vehicles));
    //setVehicles(VehicleData.data?.vehicles);
    //window.scrollTo(0, 0)
  };
  const vehicles = useSelector((state) => state.vehicles.vehicles);

  console.log("Selector Vehicles", vehicles);
  //console.log("Use State Vehicles", vehicles);

  const handleSuccess = (newVehicle) => {
    /*setVehicles((prevVehicles) => {
      const updatedVehicles = [newVehicle, ...prevVehicles];
      return updatedVehicles.slice(0, 5);
    });*/
    const updatedVehicles = [newVehicle, ...vehicles].slice(0, 5);
    dispatch(addVehicles(updatedVehicles));
  };
  const handleEdit = async (val) => {
    //editVehicle({ id: val?.id, val });
    dispatch(
      addVehicles(
        vehicles.map((vehicle) => (vehicle.id === val.id ? val : vehicle))
      )
    );
    setEdited(true);
  };
  return (
    <>
      <Helmet>
        <title>Vehicles</title>
        <meta name="description" content="Vehicles" />
      </Helmet>
      <SecondHeader
        title="Vehicles"
        add="Add new vehicle"
        onClick={handleShow}
      />
      <VehicleHead />
      {isLoading ? (
        <Loader />
      ) : (
        <Table HeadField={HeadField} BodyData={vehicles}>
          <Pagination handlePageClick={handlePageClick} />
        </Table>
      )}
      <VehicleForm onSuccess={handleSuccess} onEdit={handleEdit} />
    </>
  );
}
