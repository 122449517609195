/* eslint-disable react/style-prop-object */
import React, { useState, useEffect } from "react";
import Dropzone from "react-dropzone"; // Assuming you're using react-dropzone
import AvatarEditor from "react-avatar-editor"; // Assuming you're using react-avatar-editor
import Button from "../Button/Button";

export default function ProfilePicture() {
  const [image, setImage] = useState(
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png"
  ); // Default image

  // Function to handle file upload from Dropzone
  const handleDrop = (acceptedFiles) => {
    const file = acceptedFiles[0]; // Get the first uploaded file
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setImage(event.target.result); // Set image state with the uploaded file data URL
      };
      reader.readAsDataURL(file); // Read the uploaded file as a data URL
    }
  };

  // Function to handle fetching image from local storage (if available)
  // (Optional, if you want to pre-populate with local storage image)
  const fetchLocalImage = () => {
    const storedImage = localStorage.getItem("profileImage"); // Get the stored image data URL
    if (storedImage) {
      setImage(storedImage); // Set image state with the local storage image data URL
    }
  };

  // Fetch local image on component mount (optional)
  useEffect(() => {
    fetchLocalImage();
  }, []); // Empty dependency array to run only once on mount

  return (
    <div className="mb-24">
      <Dropzone onDrop={handleDrop} style={{ width: "250px", height: "250px" }}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className="text-center">
            <AvatarEditor
              width={102}
              height={102}
              image={image} // Use the 'image' state variable
              border={0}
              className="profile-picture-container"
            />
            <input {...getInputProps()} />
          </div>
        )}
      </Dropzone>
      <div className="text-center mt-2">
        <Button
          style="button btn-success p-4-12"
          text="Upload Image"
          onClick={() => {
            console.log("Image data URL:", image);
          }}
        />
      </div>
    </div>
  );
}
