import { createSlice } from "@reduxjs/toolkit";

export const geofencesSlice = createSlice({
  name: "geofences",
  initialState: {
    geofences: [],
  },
  reducers: {
    addGeofence: (state, action) => {
      state.geofences.push(action.payload);
    },
  },
 
});

export const { addGeofence } = geofencesSlice.actions;

export default geofencesSlice.reducer;
