import React from "react";

export default function DriverAttachment({ values = {} }) {
  const { attachments = [] } = values;
  let files = attachments.map((i, index) => {
    return (
      <div key={i.id} className="col-lg-4">
        <div className="d-flex">
          <span>{index + 1}.</span>
          <a href={i.url} target="blank">
            {i.name}
          </a>
        </div>
      </div>
    );
  });
  return (
    <>
      {attachments?.length !== 0 ? (
        <>
          <p className="fs-16 fw-700 brand-700 text-uppercase mt-3">
            attachments
          </p>
          <div className="row">{files}</div>{" "}
        </>
      ) : (
        ""
      )}
    </>
  );
}
