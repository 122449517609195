/* eslint-disable react/style-prop-object */
import React from "react";

import Checktree from "./Checktree";

export default function ObjectUsersBody() {
  return (
    <div className="p-16">
      <div className="object-users-body">
        <Checktree />
      </div>
    </div>
  );
}
