import React from 'react'
import { Input } from '../../Helpers/Input/Input';

export default function DriverDateInfo() {
    return (
      <div className="driverinfo mb-24">
        <p className="fs-16 fw-700 brand-700 text-uppercase">date info</p>
        <div className="row">
          <div className="col-lg-6">
            <Input title="Contract start date" type="date" />
            <Input
              title="Contract expiry date"
              type="date"
              placeholder="0123456789"
            />
          </div>
          <div className="col-lg-6">
            <Input
              title="Liscense start date"
              type="date"
              placeholder="License plate"
            />
            <Input title="Liscense start date" type="date" placeholder="Code" />
          </div>
        </div>
      </div>
    );
}
