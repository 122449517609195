import React from "react";
import PopupBodyItem from "../../Helpers/Popup/PopupParts/PopupBodyItem";

export default function VehicleDetails({ values = {} }) {
  const getValue = (value, defaultValue = "-----") =>
    value ? value : defaultValue;
  const {
    name = "",
    plateNumber = "",
    fuelType = "",
    idleAlert = "",
    vin = "",
    simNumber = "",
    make = "",
    model = "",
    year = "",
    fuelCapacity = "",
    tankLength = "",
    tankHeight = "",
    tankWidth = "",
    vehicleType = "",
    driver = { name: "" },
    registrationNumber = "",
    odometer = "",
    fuelSupport = "",
    fuelCost = "",
    fuelConsumption = "",
    idleTime = "",
    archived = "",
    imei = "",
    vehicleExpires = "",
    brand = "",
    color = "",
  } = values;
  return (
    <>
      <p className="fs-16 fw-700 brand-700 text-uppercase">vehicle details</p>
      <div className="row">
        <div className="col-lg-6">
          <div className="display-border ">
            <PopupBodyItem title="Name" item={getValue(name)} />
            <PopupBodyItem title="Vehicle Type" item={getValue(vehicleType)} />
            <PopupBodyItem title="Plate Number" item={getValue(plateNumber)} />
            <PopupBodyItem title="Make" item={getValue(make)} />
            <PopupBodyItem title="Model" item={getValue(model)} />
            <PopupBodyItem title="Year" item={getValue(year)} />
            <PopupBodyItem title="Brand" item={getValue(brand)} />
            <PopupBodyItem title="Color" item={getValue(color)} />
            <PopupBodyItem title="Fuel Type" item={getValue(fuelType)} />
            <PopupBodyItem
              title="Fuel Capacity"
              item={getValue(fuelCapacity)}
            />
            <PopupBodyItem title="Fuel Cost" item={getValue(fuelCost)} />
            <PopupBodyItem title="Fuel Support" item={getValue(fuelSupport)} />
            <PopupBodyItem
              title="Fuel Consumption"
              item={getValue(fuelConsumption)}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="display-border ">
            <PopupBodyItem title="Tank Height" item={getValue(tankHeight)} />
            <PopupBodyItem title="Tank Width" item={getValue(tankWidth)} />
            <PopupBodyItem title="Tank Length" item={getValue(tankLength)} />
            <PopupBodyItem title="IMEI" item={getValue(imei)} />{" "}
            <PopupBodyItem title="SIM Number" item={getValue(simNumber)} />
            <PopupBodyItem title="VIN" item={getValue(vin)} />
            <PopupBodyItem
              title="Expire Date"
              item={getValue(vehicleExpires)}
            />
            <PopupBodyItem
              title="Assigned Driver"
              item={getValue(driver.name)}
            />
            <PopupBodyItem
              title="Registration Number"
              item={getValue(registrationNumber)}
            />
            <PopupBodyItem title="Odometer" item={getValue(odometer)} />
            <PopupBodyItem title="Idle Time" item={getValue(idleTime)} />
            <PopupBodyItem title="FMS Status" item={getValue(archived)} />
            <PopupBodyItem title="Idle Alert" item={getValue(idleAlert)} />
          </div>
        </div>
      </div>
    </>
  );
}
