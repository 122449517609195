/* eslint-disable react/style-prop-object */
import React, { useContext, useState } from "react";
import {
  useGetAllUsersQuery,
  useLazyGetChildUsersQuery,
} from "../../../../../Redux/service/Users";
import { filtrationcontext } from "../../../../../context/Filtercontext";
import Searchbar from "../../../../Helpers/Searchbar/Searchbar";

export default function Checktree() {
  const { data: AllUsers, isLoading } = useGetAllUsersQuery();
  const [triggerGetChildUsers] = useLazyGetChildUsersQuery();
  const [childDataMap, setChildDataMap] = useState({});
  const [expandedParents, setExpandedParents] = useState({});
  const [toggle, setToggle] = useState(false);
  const {
    parentCheck,
    setParentCheck,
    childCheck,
    setChildCheck,
    searchQuery,
    setSearchQuery,
  } = useContext(filtrationcontext);

  const Branches = AllUsers.filter(
    (user) => user.administrator === false && user.userLimit > 0
  );

  const handleParentCheckClick = (id) => (e) => {
    const isChecked = e.target.checked;
    setParentCheck((prev) => {
      const updatedParentCheck = isChecked
        ? [...prev, id]
        : prev.filter((item) => item !== id);
      return updatedParentCheck;
    });
  };

  const handleChildCheckClick = (id) => (e) => {
    const isChecked = e.target.checked;
    setChildCheck((prev) => {
      const updatedChildCheck = isChecked
        ? [...prev, id]
        : prev.filter((item) => item !== id);
      return updatedChildCheck;
    });
  };

  const handleImageClick = (id) => async () => {
    try {
      if (!expandedParents[id]) {
        const data = await triggerGetChildUsers(id).unwrap();
        setChildDataMap((prevMap) => ({
          ...prevMap,
          [id]: data,
        }));
      }

      setExpandedParents((prev) => ({
        ...prev,
        [id]: !prev[id],
      }));
      setToggle(!toggle);
    } catch (error) {
      console.error("Failed to fetch child users", error);
    }
  };

  const filteredBranches = Branches.filter((branch) => {
    const isParentMatch = branch.name
      .toLowerCase()
      .includes(searchQuery.toLowerCase());

    const isChildMatch = childDataMap[branch.id]?.some((child) =>
      child.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return isParentMatch || isChildMatch;
  });

  if (isLoading) return <h1>Load</h1>;

  return (
    <>
      <Searchbar
        style="search-dark input-group"
        path="/assets/dark/search.svg"
        placeholder="Search..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />

      <div className="users-filter mt-3">
        {filteredBranches.map((branch) => (
          <div key={branch.id} className="mt-1">
            <div className="d-flex align-items-center">
              <div className="d-flex">
                <img
                  role="button"
                  src={
                    expandedParents[branch.id]
                      ? "assets/uplist-arrow.svg"
                      : "assets/downlist-arrow.svg"
                  }
                  alt=""
                  onClick={handleImageClick(branch.id)}
                  className="mr-8"
                />
                <input
                  type="checkbox"
                  onChange={handleParentCheckClick(branch.id)}
                  name={branch.name}
                  id={branch.name}
                  checked={parentCheck?.includes(branch.id)}
                  className="mr-8"
                />
              </div>
              <div className="fs-14 fw-400">{branch.name}</div>
            </div>

            {expandedParents[branch.id] && (
              <div className="ms-5">
                {childDataMap[branch.id]?.map((child) => (
                  <div key={child.id} className="d-flex mt-1">
                    <div className="d-flex users-checkbox">
                      <input
                        type="checkbox"
                        onChange={handleChildCheckClick(child.id)}
                        name={child.name}
                        id={child.name}
                        checked={childCheck.includes(child.id)}
                      />
                    </div>
                    <div className="fs-14 fw-400">{child.name}</div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
}
