import React from "react";
import { Input } from "../../../Helpers/Input/Input";

export default function GeofenceBody({ AddGeo }) {
  console.log(AddGeo)
  return (
    <div className="p-16">
        <Input
          formstyle="w-100 mb-2"
          title="Name"
          type="text"
          placeholder="Geofence Name"
          id="name"
          name="name"
          onBlur={AddGeo.handleBlur}
          onChange={AddGeo.handleChange}
          errors={AddGeo.errors.name}
          touched={AddGeo.touched.name}
        />
        <Input
          formstyle="w-100"
          title="Description"
          type="text"
          placeholder="Geofence Description"
          id="description"
          name="description"
          onBlur={AddGeo.handleBlur}
          onChange={AddGeo.handleChange}
        />
    </div>
  );
}
