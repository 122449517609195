import React from "react";

export default function TableRow({ value, style }) {
  return (
    <>
      <div className={`fs-14 fw-400 text-capitalize table-element ${style}`}>
        {value}
      </div>
    </>
  );
}
