/* eslint-disable react/style-prop-object */
import React from "react";
import ChartItem from "./ChartItem/ChartItem";

export default function DashboardChartNumbers() {
  return (
    <>
      <div className="mt-24">
        <div className="row">
          <div className="col-lg-6">
            <ChartItem title="Running" number="2500" style="green dots" />
            <ChartItem
              title="idle"
              number="100"
              margin="mt-16"
              style="orange dots"
            />
            <ChartItem
              title="Stopped"
              number="150"
              margin="mt-16"
              style="brown dots"
            />
            <ChartItem
              title="No data"
              number="200"
              margin="mt-16"
              style="grey dots"
            />
          </div>
          <div className="col-lg-6">
            <ChartItem title="Online" number="2500" style="blue dots" />
            <ChartItem
              title="GPS not updated"
              number="100"
              margin="mt-16"
              style="purple dots"
            />
            <ChartItem
              title="Offline"
              number="150"
              margin="mt-16"
              style="tomato dots"
            />
            <ChartItem
              title="Other"
              number="200"
              margin="mt-16"
              style="gray-300 dots"
            />
          </div>
        </div>
      </div>
    </>
  );
}
