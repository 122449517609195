import React from "react";

export default function AlertsComponent(props) {
  return (
    <>
      <div className="dashboard-alert ">
        <div className="bg-white-rounded flex-between my-3">
          <div>
            <p className="fs-14 fw-500 neutral-500">{props.title}</p>
            <div>
              <span className="fs-18 fw-700 neutral-800 me-3">
                {props.number}
              </span>
              <span className="fs-14 fw-700 brand-500">{props.percent}</span>
            </div>
          </div>
          <div className="">
            <img src={`assets/${props.image}.svg`} alt="" />
          </div>
        </div>
      </div>
    </>
  );
}
