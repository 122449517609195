import React, { useContext } from "react";
import Delete from "../Delete/DeletePopup";
import { popupcontext } from "../../../context/Popupscontext";
import Display from "./Table Actions/Display";
import { useSelector } from "react-redux";

export default function TableActions({ vehicleId, DriverId }) {
  const { handleShow } = useContext(popupcontext);
  const inf = useSelector((state)=>state.users.user)
  console.log("vehicel Id", vehicleId)
  const handleEditClick = () => {
    if (vehicleId) {
      handleShow("editForm", "vehicles", vehicleId);
    }
    if (DriverId) {
      handleShow("editForm", "drivers", DriverId);
    }
  };

  const handleDeleteClick = () => {
    if (DriverId) {
      handleShow("deleteItem", "drivers", DriverId);
    }
    if (vehicleId) {
      handleShow("deleteItem", "vehicles", vehicleId);
    }
  };
  return (
    <div className="table-element bg-transparent">
      <div className="d-flex table-actions ">
        <Display vehicleId={vehicleId} DriverId={DriverId} />

        {(inf.administrator === true || (inf.administrator===false && (inf.deviceLimit > 0 || inf.deviceLimit === -1))) && (<> <img
          role="button"
          src="/assets/Edit.svg"
          alt="edit"
          onClick={handleEditClick}
        />
        <Delete
          vehicleId={vehicleId}
          DriverId={DriverId}
          handleDeleteClick={handleDeleteClick}
        /></>)}
       
      </div>
    </div>
  );
}
