import React, { useContext, useEffect } from "react";
import VehicleInfo from "./VehicleInfo";
import VehicleSetup from "./VehicleSetup";
import VehicleSensors from "./VehicleSensors";
import Dropfile from "../../Helpers/Dropzone/Dropzone";
import OffcanvasFooter from "../../Helpers/Offcanvasfooter/OffcanvasFooter";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  useAddVehiclesMutation,
  useEditVehicleMutation,
} from "../../../Redux/service/Vehicles/Vehicles";
import { popupcontext } from "../../../context/Popupscontext";

export default function VehicleBody({ onSuccess, Editvalues, onEdit }) {
  let [addNewVehicle] = useAddVehiclesMutation();
  let [editVehicle] = useEditVehicleMutation();
  const { isEditing } = useContext(popupcontext);

  const initialValues = {
    name: isEditing ? Editvalues?.name : "",
    code: isEditing ? Editvalues?.code : "",
    imei: isEditing ? Editvalues?.imei : "",
    make: isEditing ? Editvalues?.make : "",
    model: isEditing ? Editvalues?.model : "",
    registrationNumber: isEditing ? Editvalues?.registrationNumber : "",
    fuelType: isEditing ? Editvalues?.fuelType : "",
    fuelConsumption: isEditing ? Editvalues?.fuelConsumption : null,
    fuelCost: isEditing ? Editvalues?.fuelCost : null,
    vehicleType: isEditing ? Editvalues?.vehicleType : "",
    plateNumber: isEditing ? Editvalues?.plateNumber : "",
    vin: isEditing ? Editvalues?.vin : "",
    licenseExpire: isEditing ? Editvalues?.licenseExpire : "",
    licenseExpireReminder: isEditing
      ? Editvalues?.licenseExpireReminder
      : false,
    simNumber: isEditing ? Editvalues?.simNumber : "",
    simNumberSerial: isEditing ? Editvalues?.simNumberSerial : "",
    odometer: isEditing ? Editvalues?.odometer : null,
    brand: isEditing ? Editvalues?.brand : "",
    color: isEditing ? Editvalues?.color : "",
    year: isEditing ? Editvalues?.year : null,
    vehicleExpires: isEditing ? Editvalues?.vehicleExpires : "",
    parent: isEditing ? Editvalues?.parent : "",
    accSupport: isEditing ? Editvalues?.accSupport : false,
    fuelSupport: isEditing ? Editvalues?.fuelSupport : false,
    fuelCapacity: isEditing ? Editvalues?.fuelCapacity : "",
    tankHeight: isEditing ? Editvalues?.tankHeight : "",
    tankWidth: isEditing ? Editvalues?.tankWidth : "",
    tankLength: isEditing ? Editvalues?.tankLength : "",
    doorSupport: isEditing ? Editvalues?.doorSupport : false,
    weightSensorSupport: isEditing ? Editvalues?.weightSensorSupport : false,
    temperatureSensorSupport: isEditing
      ? Editvalues?.temperatureSensorSupport
      : false,
    iButtonSensorSupport: isEditing ? Editvalues?.iButtonSensorSupport : false,
    ptoSensorSupport: isEditing ? Editvalues?.ptoSensorSupport : false,
    seatSensorSupport: isEditing ? Editvalues?.seatSensorSupport : false,
    refrigeratorSensorSupport: isEditing
      ? Editvalues?.refrigeratorSensorSupport
      : false,
    headlightsSensorSupport: isEditing
      ? Editvalues?.headlightsSensorSupport
      : false,
    idleTime: isEditing ? Editvalues?.idleTime : "",
    idleAlert: isEditing ? Editvalues?.idleAlert : false,
    archived: isEditing ? Editvalues?.archived : false,
    department: isEditing ? Editvalues?.department : 1,
    driverId: isEditing ? Editvalues?.driverId : 1,
    icon: isEditing ? Editvalues?.icon : "icon-car.png",
    attachments: isEditing ? Editvalues?.attachments : [],
  };

  let validSchema = Yup.object({
    name: Yup.string().required("Name Is Required"),
    imei: Yup.string().required("IMEI Is Required"),
    plateNumber: Yup.string().required("Plate Number Is Required"),
    vehicleType: Yup.string().required("Vehicle Type Is Required"),
    licenseExpire: Yup.string()
      .when("licenseExpireReminder", {
        is: true,
        then: (licenseExpire) =>
          licenseExpire.required("License Expiration Is Required"),
      })
      .nullable(),
    simNumber: Yup.string().required("Sim Number Is Required"),
    vehicleExpires: Yup.string().required("Vehicle Expiration Is Required"),
    parent: Yup.number().required("Parent Is Required"),
    fuelCapacity: Yup.number()
      .typeError("Fuel Capacity must be a number")
      .when("fuelSupport", {
        is: true,
        then: (fuelCapacity) =>
          fuelCapacity.required("Fuel Capacity Is Required"),
      })
      .nullable(),
    tankHeight: Yup.number()
      .typeError("Tank Height must be a number")
      .when("fuelSupport", {
        is: true,
        then: (tankHeight) => tankHeight.required("Tank Height Is Required"),
      })
      .nullable(),
    tankWidth: Yup.number()
      .typeError("Tank Width must be a number")
      .when("fuelSupport", {
        is: true,
        then: (tankWidth) => tankWidth.required("Tank Width Is Required"),
      })
      .nullable(),
    tankLength: Yup.number()
      .typeError("Tank Length must be a number")
      .when("fuelSupport", {
        is: true,
        then: (tankLength) => tankLength.required("Tank Length Is Required"),
      })
      .nullable(),
    idleTime: Yup.number()
      .typeError("Idle Time must be a number")
      .when("idleAlert", {
        is: true,
        then: (idleTime) => idleTime.required("Idle Time Is Required"),
      })
      .nullable(),
    year: Yup.number().typeError("Year must be a number").nullable(),
    odometer: Yup.number().typeError("Odometer must be a number").nullable(),
  });

  const addVehicle = useFormik({
    initialValues,
    validationSchema: validSchema,
    onSubmit: async (val, { validateForm, setTouched, setSubmitting }) => {
      if (val.licenseExpireReminder === false) {
        delete val.licenseExpire;
      }

      if (val.fuelSupport === false) {
        delete val.fuelCapacity;
        delete val.tankHeight;
        delete val.tankWidth;
        delete val.tankLength;
      }

      if (val.idleAlert === false) {
        delete val.idleTime;
      }

      const errors = await validateForm();
      if (Object.keys(errors).length > 0) {
        setTouched({
          name: true,
          imei: true,
          plateNumber: true,
          simNumber: true,
          licenseExpire: true,
          vehicleExpires: true,
          parent: true,
          fuelCapacity: true,
          tankHeight: true,
          tankWidth: true,
          tankLength: true,
        });
      } else {
        try {
          if (isEditing) {
            delete val.attachments;
            delete val.odometer;
            const updatedVehicle = await editVehicle({
              id: Editvalues.id,
              val,
            }).unwrap();
            if (onEdit) {
              onEdit(updatedVehicle);
            }
          } else {
            console.log("Submitting New Vehicle:", val); // Log the payload
            const addedVehicle = await addNewVehicle(val).unwrap();
            if (onSuccess) {
              await onSuccess(addedVehicle);
            }
          }
        } catch (error) {
          console.error("Error submitting form:", error);
        }
      }
    },
  });

  useEffect(() => {
    if (isEditing && Editvalues) {
      addVehicle.setValues({
        ...initialValues,
        name: Editvalues?.name,
        code: Editvalues?.code,
        imei: Editvalues?.imei,
        make: Editvalues?.make,
        model: Editvalues?.model,
        registrationNumber: Editvalues?.registrationNumber,
        fuelType: Editvalues?.fuelType,
        fuelConsumption: Editvalues?.fuelConsumption,
        fuelCost: Editvalues?.fuelCost,
        vehicleType: Editvalues?.vehicleType,
        plateNumber: Editvalues?.plateNumber,
        vin: Editvalues?.vin,
        licenseExpire: Editvalues?.licenseExpire,
        licenseExpireReminder: Editvalues?.licenseExpireReminder,
        simNumber: Editvalues?.simNumber,
        simNumberSerial: Editvalues?.simNumberSerial,
        odometer: Editvalues?.odometer,
        brand: Editvalues?.brand,
        color: Editvalues?.color,
        year: Editvalues?.year,
        vehicleExpires: Editvalues?.vehicleExpires,
        parent: Editvalues?.parent,
        accSupport: Editvalues?.accSupport,
        fuelSupport: Editvalues?.fuelSupport,
        fuelCapacity: Editvalues?.fuelCapacity,
        tankHeight: Editvalues?.tankHeight,
        tankWidth: Editvalues?.tankWidth,
        tankLength: Editvalues?.tankLength,
        doorSupport: Editvalues?.doorSupport,
        weightSensorSupport: Editvalues?.weightSensorSupport,
        temperatureSensorSupport: Editvalues?.temperatureSensorSupport,
        iButtonSensorSupport: Editvalues?.iButtonSensorSupport,
        ptoSensorSupport: Editvalues?.ptoSensorSupport,
        seatSensorSupport: Editvalues?.seatSensorSupport,
        refrigeratorSensorSupport: Editvalues?.refrigeratorSensorSupport,
        headlightsSensorSupport: Editvalues?.headlightsSensorSupport,
        idleTime: Editvalues?.idleTime,
        idleAlert: Editvalues?.idleAlert,
        archived: Editvalues?.archived,
        department: Editvalues?.department,
        driverId: Editvalues?.driverId,
        icon: Editvalues?.icon,
        attachments: Editvalues?.attachments,
      });
    }
  }, [Editvalues]);
  return (
    <form onSubmit={addVehicle.handleSubmit}>
      <VehicleInfo addData={addVehicle} />
      <VehicleSetup addData={addVehicle} />
      <VehicleSensors addData={addVehicle} />
      {!isEditing && <Dropfile addData={addVehicle} name="attachments" />}
      <OffcanvasFooter addData={addVehicle} />
    </form>
  );
}
