import { wktToGeoJSON } from "@terraformer/wkt";
import { useMap } from "react-leaflet";
import L from "leaflet";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useGetAllGeofencesQuery } from "../../Redux/service/Geofences/Geofences";

export default function Geofences() {
  const map = useMap();
  const { data: initialGeofences } = useGetAllGeofencesQuery();
  const geofences = useSelector((state) => state.geofences.geofences);

  const GeoName = (latLngs, name) => {
    let polygonLayer = L.polyline(latLngs);
    let centroid = polygonLayer.getBounds().getCenter();
    L.marker(centroid, {
      icon: L.divIcon({
        className: "label-icon",
        html: `<div>${name}</div>`,
      }),
      interactive: false,
    }).addTo(map);
  };

  const renderGeofencesOnMap = (geofences) => {
    geofences.forEach((i) => {
      let geojsonLayer = wktToGeoJSON(i?.area);
      if (geojsonLayer.type === "LineString") {
        let latLngs = geojsonLayer.coordinates.map(([lng, lat]) =>
          L.latLng(lng, lat)
        );
        L.polyline(latLngs).addTo(map);
        GeoName(latLngs, i?.name);
      } else if (geojsonLayer.type === "Polygon") {
        geojsonLayer.coordinates.forEach((coord) => {
          let latLngs = coord.map(([lng, lat]) => L.latLng(lng, lat));
          L.polygon(latLngs , {color : "#22c55e"}).addTo(map);
          GeoName(latLngs, i?.name);
        });
      }
    });
  };

  useEffect(() => {
    if (initialGeofences) {
      renderGeofencesOnMap(initialGeofences);
    }

    if (geofences.length > 0) {
      renderGeofencesOnMap(geofences);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialGeofences, geofences]);
}
