/* eslint-disable react/style-prop-object */
import React, { useContext, useEffect, useState } from "react";
import Searchbar from "../../../Helpers/Searchbar/Searchbar";
import ObjectUsers from "../ObjectUsers/ObjectUsers";
import { popupcontext } from "../../../../context/Popupscontext";
import Button from "../../../Helpers/Button/Button";
import { filtrationcontext } from "../../../../context/Filtercontext";

export default function ObjectSearch() {
  const { display, handleShow, setDisplay } = useContext(popupcontext);
  const { setObjectSearchQuery } = useContext(filtrationcontext); // Use context to set search query
  const [query, setQuery] = useState("");

  useEffect(() => {
    return () => {
      setDisplay(false);
    };
  }, [setDisplay]);

  const handleSearch = (e) => {
    setQuery(e.target.value);
    setObjectSearchQuery(e.target.value);
  };

  return (
    <>
      <div className="d-flex align-items-center p-8 my-8">
        <div className="object-search">
          <Searchbar
            style="search-dark input-group"
            path="/assets/dark/search.svg"
            placeholder="Search..."
            value={query}
            onChange={handleSearch}
          />
        </div>

        <Button
          text="Users"
          textstyle="fs-14 neutral-500"
          style="button btn-default object-btn p-8-16 ms-2"
          type="button"
          onClick={() => {
            handleShow("objectUsers");
          }}
        />
      </div>
      {display && <ObjectUsers />}
    </>
  );
}