import React, { useContext, useRef } from "react";
import ObjectHead from "../ObjectHead/ObjectHead";
import ObjectSearch from "../ObjectSearch/ObjectSearch";
import { popupcontext } from "../../../../context/Popupscontext";
import useOutsideClick from "../../../Helpers/OutsideClick/useOutsideClick";
import ObjectTable from "../ObjectTable/ObjectTable";

export default function ObjectDetails() {
  let { setObjectbody } = useContext(popupcontext);
  const obj = useRef(null);

  useOutsideClick(obj, () => setObjectbody(false), "mousedown");
  return (
    <div ref={obj}>
      <div className="bg-white-rounded p-0 object-details">
        <ObjectHead />
        <div className="H-line"></div>
        <ObjectSearch />
        <div className="H-line"></div>
        <ObjectTable />
        {/**<div className="histroy-playback">
          <img src="assets/Playback.svg" alt="historyplayback" />
        </div>**/}
      </div>
    </div>
  );
}
