import React from "react";

export default function DashboardChart() {
  return (
    <>
      <div className="dashboard-chart flex-between-end">
        <div
          className="white chart"
          style={{ height: (4000 / 4000) * 100 }}
        ></div>
        <div
          className="tomato chart"
          style={{ height: (1500 / 4000) * 100 }}
        ></div>
        <div
          className="purple chart"
          style={{ height: (900 / 4000) * 100 }}
        ></div>
        <div
          className="green chart"
          style={{ height: (2500 / 4000) * 100 }}
        ></div>
        <div
          className="blue chart"
          style={{ height: (2700 / 4000) * 100 }}
        ></div>
        <div
          className="orange chart"
          style={{ height: (100 / 4000) * 100 }}
        ></div>
        <div
          className="grey chart"
          style={{ height: (200 / 4000) * 100 }}
        ></div>
        <div
          className="brown chart"
          style={{ height: (150 / 4000) * 100 }}
        ></div>
      </div>
    </>
  );
}
