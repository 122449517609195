/* eslint-disable react/style-prop-object */
import React, { useContext } from "react";
import { sidebarcontext } from "../../context/Sidebarcontext";
import Searchbar from "../Helpers/Searchbar/Searchbar";

export default function Header() {
  let { isSideOpen } = useContext(sidebarcontext);

  return (
    <div className={isSideOpen ? "header header-max" : "header header-min"}>
      <div className="flex-between w-100">
        <Searchbar
          style="search input-group"
          path="/assets/dark/search.svg"
          placeholder="Search anything"
        />
        <div className="header-tools flex-between">
          <div className="notify border-16">
            <img src="/assets/dark/notifications.svg" alt="notification" />
            <div className="notify-count"></div>
          </div>
          <div className="border-16">
            <img src="/assets/dark/expand.svg" alt="expand" />
          </div>
          <div className="header-username">
            <p>FM</p>
          </div>
        </div>
      </div>
    </div>
  );
}
