/* eslint-disable react/style-prop-object */
import React, { useContext } from "react";
import SecondHeader from "../Header/SecondHeader";
import DriversHead from "./DriversParts/DriversHead";
import TableHead from "../Helpers/Table/TableHead";
import TableBody from "../Helpers/Table/TableBody";
import { popupcontext } from "../../context/Popupscontext";
import { DriverForm } from "../Helpers/Form/FormComponents";
import { Helmet } from "react-helmet";

export default function Drivers() {
  let { handleShow } = useContext(popupcontext);
  const HeadField = [
    "Driver Code",
    "Driver Name",
    "ID Number",
    "Phone number",
    "Address",
    "Actions",
  ];
  const BodyData=["42145487623" , "Omar Ahmed", "512345678974" , "+9856484531214" , "Riydah"]
  return (
    <>
      <Helmet>
        <title>Drivers</title>
        <meta name="description" content="Drivers" />
      </Helmet>
      <SecondHeader title="Drivers" add="Add new driver" onClick={handleShow} />
      <DriversHead />
      <div className="bg-white-rounded-top maxheight mt-3 ">
        <div className="table ">
          <TableHead HeadField={HeadField} style="p-16"/>
          <TableBody BodyData={BodyData} />
        </div>
      </div>
      <DriverForm />
    </>
  );
}
