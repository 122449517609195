import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import SelectionItem from "./SelectionItem/SelectionItem";

export default function Selection() {
  const location = useLocation();
  return (
    <>
      <div className="select-items ">
        <NavLink
          to={location.pathname === "/" ? "/" : "/dashboard"}
          className="select-item  flex-center"
        >
          <div className="select-icons">
            <img
              className="icons"
              src="/assets/dark/dashboard.svg"
              alt="dashboard"
            />
          </div>
          <p className="select-text">dashboard</p>
        </NavLink>
        <SelectionItem
          path="/livetracking"
          icon="livetracking"
          name="live tracking"
        />
        <SelectionItem path="reports" icon="reports" name="reports" />
        <SelectionItem path="historical" icon="historical" name="historical" />
        <SelectionItem
          path="maintenance"
          icon="maintenance"
          name="maintenance"
        />
        <SelectionItem path="accidents" icon="accidents" name="accidents" />
        <SelectionItem
          path="notifications"
          icon="notifications"
          name="notifications"
        />
        <SelectionItem path="insurance" icon="insurance" name="insurance" />
      </div>
    </>
  );
}
