import React from "react";
export default function PositionsPopupsDetails({ title, item }) {
  let formattedItem = item;
  const options = {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
    hour: "numeric",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  };
  if (title === "fix time") {
    const dateObject = new Date(item);
    formattedItem = dateObject.toLocaleDateString("en-US", options);
  }
  return (
    <>
      <div className="device-popup-row">
        <div className="device-pop-title">{title}</div>
        <div className="device-pop-item">{formattedItem}</div>
      </div>
    </>
  );
}
