/* eslint-disable react/style-prop-object */
import React, { useContext } from "react";
import Close from "../../../Helpers/CloseBtn/Close";
import { popupcontext } from "../../../../context/Popupscontext";
export default function ObjectSelectedHead({values}) {
  let { handleCloseObject, handleCloseSelected, setObjectselect } =
    useContext(popupcontext);
  return (
    <>
      <img src="/assets/streetview.jpeg" alt="" />
      <div className="streetview-overlay"></div>
      <div className="street-view-head">
        <div>
          <img
            src="/assets/dark/Double-arrow.svg"
            alt="Double-Arrow"
            onClick={handleCloseSelected}
            role="button"
          />
        </div>
        <p className="m-0 fs-16 fw-600">{values?.name}</p>
        <div className="status-online-light"></div>
      </div>
      <div className="street-view-exit">
        <Close
          style="closedark close-32"
          close={() => {
            handleCloseObject();
            setObjectselect(null);
          }}
          img="closedark"
        />
      </div>
      <div className="street-view-hand">
        <div>
          <img src="/assets/hand-point.svg" alt="handpoint" />
        </div>
        <div>Street view</div>
      </div>
    </>
  );
}
