import React, { useContext } from "react";
import { popupcontext } from "../../../../context/Popupscontext";
import { DriverPopup, VehiclePopup } from "../../Popup/PopupComponents";

export default function Display({ vehicleId, DriverId }) {
  const {
    currentVehicleId,
    currentDriverId,
    showDetails,
    isDeleting,
    isEditing,
    setIsDeleting,
  } = useContext(popupcontext);

  const handleDisplayClick = () => {
    if (isDeleting) {
      setIsDeleting(false);
    }
    if (vehicleId) showDetails("vehicles", vehicleId);

    if (DriverId) {
      showDetails("drivers", DriverId);
    }
  };

  return (
    <>
      <img
        role="button"
        src="/assets/Eye.svg"
        alt="show"
        onClick={handleDisplayClick}
      />
      {currentVehicleId === vehicleId &&
        isEditing === false &&
        isDeleting === false && (
          // Prevent display popup if deleting
          <VehiclePopup vehicleId={currentVehicleId} />
        )}

      {currentDriverId === DriverId &&
        isEditing === false &&
        isDeleting === false && (
          // Prevent display popup if deleting
          <DriverPopup DriverId={currentDriverId} />
        )}
    </>
  );
}
