// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fs-12 {
  font-size: 12px;
}
.fs-14 {
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
}
.fs-20 {
  font-size: 20px;
}
.fs-24 {
  font-size: 24px;
}
.fs-30 {
  font-size: 12px;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}
.fw-800 {
  font-weight: 800;
}
.fw-900 {
  font-weight: 900;
}
`, "",{"version":3,"sources":["webpack://./src/Styles/ColorsFonts/fonts.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;AACA;EACE,eAAe;AACjB;AACA;EACE,eAAe;AACjB;AACA;EACE,eAAe;AACjB;AACA;EACE,eAAe;AACjB;AACA;EACE,eAAe;AACjB;AACA;EACE,eAAe;AACjB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".fs-12 {\r\n  font-size: 12px;\r\n}\r\n.fs-14 {\r\n  font-size: 14px;\r\n}\r\n.fs-16 {\r\n  font-size: 16px;\r\n}\r\n.fs-18 {\r\n  font-size: 18px;\r\n}\r\n.fs-20 {\r\n  font-size: 20px;\r\n}\r\n.fs-24 {\r\n  font-size: 24px;\r\n}\r\n.fs-30 {\r\n  font-size: 12px;\r\n}\r\n.fw-400 {\r\n  font-weight: 400;\r\n}\r\n.fw-500 {\r\n  font-weight: 500;\r\n}\r\n.fw-600 {\r\n  font-weight: 600;\r\n}\r\n.fw-700 {\r\n  font-weight: 700;\r\n}\r\n.fw-800 {\r\n  font-weight: 800;\r\n}\r\n.fw-900 {\r\n  font-weight: 900;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
