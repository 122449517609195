import React from "react";
import Maps from "../Maps/Maps";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function LiveTracking() {
  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <title>Live Tracking</title>
          <meta name="description" content="LiveTracking" />
        </Helmet>
        <Maps />
      </HelmetProvider>
    </div>
  );
}
