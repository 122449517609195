import React from "react";
import { createContext, useState } from "react";
export const popupcontext = createContext();
export default function PopupContext({ children }) {
  const [form, setForm] = useState(false);
  const [display, setDisplay] = useState(false);
  const [remove, setRemove] = useState(false);
  const [object, setObject] = useState(false);
  const [objectbody, setObjectbody] = useState(true);
  const [objectselect, setObjectselect] = useState(false);
  const [displayGeo, setDisplayGeo] = useState(false);
  const [displayId, setDisplayId] = useState(false);
  const [currentVehicleId, setCurrentVehicleId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false); // New state for delete action
  const [isEditing, setIsEditing] = useState(false);
  const [edited, setEdited] = useState(false);
  const [deleted, setDeleted] = useState(false); // New state for delete action

  const handleClose = () => {
    setForm(false);
    setIsEditing(false);
    setCurrentVehicleId(null);
  };
  const handleShow = () => {
    setForm(true);
  };

  const handleEditForm = (id) => {
    setCurrentVehicleId(id);
    setIsEditing(true);
    setForm(true);
  };

  const handleDisplay = () => setDisplay(true);
  const handleCloseDisplay = () => setDisplay(false);

  // const handleRemove = () => setRemove(true);
  // const handleCloseRemove = () => setRemove(false);

  const handleShowObject = () => setObject(true);
  const handleCloseObject = () => setObject(false);

  const handleShowObjectBody = () => setObjectbody(true);
  const handleCloseObjectBody = () => setObjectbody(false);

  const handleShowSelected = () => setObjectselect(true);
  const handleCloseSelected = () => setObjectselect(false);

  const handleShowGeo = () => setDisplayGeo(true);
  const handleCloseGeo = () => setDisplayGeo(false);

  const handleRemove = (vehicleId) => {
    setCurrentVehicleId(vehicleId);
    setRemove(true);
    setIsDeleting(true); // Mark that delete action is active
  };

  const handleCloseRemove = () => {
    setRemove(false);
    setCurrentVehicleId(null);
    setIsDeleting(false); // Reset delete action
  };

  const showDetails = (vehicleId) => {
    if (isDeleting === false) {
      // Prevent showing details when delete action is active
      setCurrentVehicleId(vehicleId);
    }
  };

  const hideDetails = () => {
    setCurrentVehicleId(null);
  };

  return (
    <>
      <popupcontext.Provider
        value={{
          form,
          setForm,
          handleClose,
          handleShow,
          handleDisplay,
          handleCloseDisplay,
          display,
          setDisplay,
          handleRemove,
          handleCloseRemove,
          remove,
          object,
          handleShowObject,
          handleCloseObject,
          objectbody,
          handleShowObjectBody,
          handleCloseObjectBody,
          setObjectbody,
          objectselect,
          setObjectselect,
          handleShowSelected,
          handleCloseSelected,
          displayGeo,
          handleShowGeo,
          handleCloseGeo,
          displayId,
          currentVehicleId,
          setCurrentVehicleId,
          showDetails,
          hideDetails,
          setIsDeleting,
          isDeleting,
          deleted,
          setDeleted,
          isEditing,
          setIsEditing,
          edited,
          setEdited,
          handleEditForm,
        }}
      >
        {children}
      </popupcontext.Provider>
    </>
  );
}
