import React from "react";
import GeofenceHead from "./GeofenceHead/GeofenceHead";
import GeofenceBody from "./GeofenceBody/GeofenceBody";
import GeofenceFooter from "./GeofenceFooter/GeofenceFooter";
import * as Yup from "yup";
import { useFormik } from "formik";

export default function GeofenceForm({ area }) {
  let validSchema = Yup.object({
    name: Yup.string().required("Name Is Required"),
  });

  let AddGeo = useFormik({
    initialValues: {
      name: "",
      description: "",
      area: area,
    },
    validationSchema: validSchema,
  });
  console.log("GERRR" , AddGeo)
  return (
    <div className="Geofence-Form">
      <GeofenceHead />
      <div className="H-line"></div>
      <GeofenceBody AddGeo={AddGeo} />
      <div className="H-line"></div>
      <GeofenceFooter AddGeo={AddGeo} />
    </div>
  );
}
