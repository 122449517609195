import React from "react";
import VehiclesTableBody from "../../Vehicles/VehiclesTableBody/VehiclesTableBody";
import DriverTableBody from "../../Drivers/DriverTableBody/DriverTableBody";

export default function TableBody({ BodyData }) {
  const isVehicleData = BodyData?.some((i) => i?.plateNumber);
  return (
    <div className="table-body">
      {isVehicleData ? (
        <VehiclesTableBody data={BodyData} />
      ) : (
        <DriverTableBody data={BodyData} />
      )}
    </div>
  );
}
