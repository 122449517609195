import React from "react";
import AlertsComponent from "./DashboardAlerts/AlertsComponent";

export default function DashboardAlerts() {
  return (
    <>
      <div className="row">
        <AlertsComponent
          title="SOS Alerts"
          percent="+8%"
          number="170"
          image="SosAlert"
        />
        <AlertsComponent
          title="Speed Alerts"
          percent="+14%"
          number="52"
          image="SpeedAlert"
        />
        <AlertsComponent
          title="Idle Alerts"
          percent="+3%"
          number="52"
          image="IdleAlert"
        />
        <AlertsComponent
          title="Power Cut"
          percent="+44%"
          number="170"
          image="Powercut"
        />
        <AlertsComponent
          title="Geofence Alerts"
          percent="+44%"
          number="170"
          image="GeofenceAlert"
        />
        <AlertsComponent
          title="Other Alerts"
          percent="+2%"
          number="20"
          image="OtherAlert"
        />
      </div>
    </>
  );
}
