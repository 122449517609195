/* eslint-disable react/style-prop-object */
import React, { useContext } from "react";
import Button from "../../../Helpers/Button/Button";
import { useAddGeofencesMutation } from "../../../../Redux/service/Geofences/Geofences";
import { popupcontext } from "../../../../context/Popupscontext";
import { useDispatch } from "react-redux";
import { addGeofence } from "../../../../Redux/service/Geofences/GeofenceSlice";
import Loader from "../../../Helpers/Loader/Loader";

export default function GeofenceFooter({ AddGeo }) {
  const [addGeofences, { data, isLoading, isSuccess }] =
    useAddGeofencesMutation();
  console.log("Data added", data);
  const dispatch = useDispatch();

  let { handleCloseGeo } = useContext(popupcontext);
  const handleAddGeo = async (val) => {
    try {
      const addedGeofence = await addGeofences(val).unwrap();
      dispatch(addGeofence(addedGeofence));
    } catch (error) {
      console.log(error);
    }
  };
  if (isSuccess) handleCloseGeo();
  return (
    <>
      <div className="d-flex flex-row-reverse mt-2 p-16">
        <Button
          style="button btn-success p-6-12"
          type="button"
          text={isLoading ? <Loader /> : "Save"}
          textstyle="fs-16"
          disabled={!(AddGeo.dirty && AddGeo.isValid)}
          onClick={() => {
            handleAddGeo(AddGeo.values);
          }}
        />
        <Button
          style="button btn-default object-btn p-6-12 me-2"
          type="button"
          text={isLoading ? <Loader /> : "Cancel"}
          textstyle="fs-16"
          onClick={handleCloseGeo}
        />
      </div>
    </>
  );
}
