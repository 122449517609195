import React, { createContext, useState } from "react";
export const sidebarcontext = createContext();
export default function Sidebarcontext({ children }) {
  const [isSideOpen, setIsSideOpen] = useState(true);
  const [openDropdown, setOpenDropdown] = useState(null);

  const handleToggle = () => {
    setIsSideOpen(!isSideOpen);
  };

  return (
    <>
      <sidebarcontext.Provider
        value={{
          isSideOpen,
          setIsSideOpen,
          handleToggle,

          openDropdown,
          setOpenDropdown,
        }}
      >
        {children}
      </sidebarcontext.Provider>
    </>
  );
}
