/* eslint-disable react/style-prop-object */
import React from "react";
import TotalComponent from "./DashboardTotal/TotalComponent";

export default function DashboardTotal() {
  return (
    <>
      <div className="bg-white-rounded">
        <div className="row justify-content-between">
          <TotalComponent
            title="Total Objects"
            percent="+2.4% in last month"
            number="4000"
            chart="Purplechart"
            status="Increase"
            style="col-lg-3"
          />
          <TotalComponent
            title="Total Drivers"
            percent="+2.4% in last month"
            number="700"
            chart="Bluechart"
            status="Increase"
            style="col-lg-3"
          />
          <TotalComponent
            title="Total Users"
            percent="-2.4% in last month"
            number="500"
            chart="Redchart"
            status="Decrease"
            style="col-lg-3"
          />
          <TotalComponent
            title="Maintenance Requests"
            percent="+2.4% in last month"
            number="500"
            chart="Greenchart"
            status="Increase"
            style="col-lg-2"
          />
        </div>
      </div>
    </>
  );
}
