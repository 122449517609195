import React, { createContext, useState } from "react";
export const filtrationcontext = createContext();

export default function Filtercontext({ children }) {
  let [checked, setChecked] = useState([]);
  let [parentCheck, setParentCheck] = useState([]);
  let [childCheck, setChildCheck] = useState([]);
  const [filter, setFilter] = useState(false);
  let [filteredData, setFilteredData] = useState([])
  let [checkValues, setcheckValues] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const handleFilter = () => setFilter(true);
  return (
    <>
      <filtrationcontext.Provider
        value={{
          checked,
          setChecked,
          filter,
          setFilter,
          handleFilter,
          checkValues,
          setcheckValues,
          searchQuery,
          setSearchQuery,

          parentCheck,
          setParentCheck,
          childCheck,
          setChildCheck,
          filteredData,
          setFilteredData,
        }}
      >
        {children}
      </filtrationcontext.Provider>
    </>
  );
}
