/* eslint-disable react/style-prop-object */
import React from "react";
import Button from "../Helpers/Button/Button";

export default function SecondHeader({ title, add, onClick }) {
  return (
    <>
      <div className="bg-white p-16 border-bottom">
        <div className="flex-between">
          <div className="fs-24 fw-600 text-capitalize">{title}</div>
          <div className="flex-between">
            <Button
              style="button p-4-10 btn-default me-2"
              text="Upload File"
              img="Upload.svg"
            />
            <Button
              style="button p-4-10 btn-success me-2"
              text={add}
              onClick={onClick}
            />
          </div>
        </div>
      </div>
    </>
  );
}
