import React from "react";
import { useMap } from "react-leaflet";

export default function ObjectMinimized({ title, minimize }) {
  const map = useMap();
  map.scrollWheelZoom.enable();
  return (
    <>
      <div
        className={
          title === "object"
            ? "object-minimized object-details"
            : "object-minimized object-selected"
        }
        role="button"
        onClick={minimize}
      >
        <div>{title}</div>
        <img src="/assets/Double-revarrow.svg" alt="doublearrow" />
      </div>
    </>
  );
}
