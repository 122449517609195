import React, { useContext, useEffect } from "react";
import Form from "./Form";
import { popupcontext } from "../../../context/Popupscontext";
import FormHeader from "./FormParts/FormHeader";
import { Offcanvas } from "react-bootstrap";
import DriverBody from "../../Drivers/DriversForm/DriverBody";
import VehicleBody from "../../Vehicles/VehicleForm/VehicleBody";
import { useLazyGetSpecificVehicleQuery } from "../../../Redux/service/Vehicles/Vehicles";
import Loader from "../Loader/Loader";

export function DriverForm() {
  let { form } = useContext(popupcontext);
  return (
    <>
      <Form show={form}>
        <Offcanvas.Header>
          <FormHeader title="add new driver" />
        </Offcanvas.Header>
        <div className="H-line"></div>
        <Offcanvas.Body>
          <DriverBody />
        </Offcanvas.Body>
      </Form>
    </>
  );
}

export function VehicleForm({ onSuccess, onEdit }) {
  let { form, currentVehicleId, isEditing } = useContext(popupcontext);
  const [triggerGetSpecificVehicle, { data: vehicleDetails, isLoading }] =
    useLazyGetSpecificVehicleQuery();
  useEffect(() => {
    if (isEditing) {
      triggerGetSpecificVehicle(currentVehicleId, true);
    }
  }, [triggerGetSpecificVehicle, currentVehicleId, isEditing]);
  if (isEditing && isLoading) return <Loader />;
  return (
    <>
      <Form show={form}>
        <Offcanvas.Header>
          <FormHeader title={isEditing ? "Edit Vehicle" : "Add new vehicle"} />
        </Offcanvas.Header>
        <div className="H-line"></div>
        <Offcanvas.Body>
          <VehicleBody
            onSuccess={onSuccess}
            onEdit={onEdit}
            Editvalues={vehicleDetails}
          />
        </Offcanvas.Body>
      </Form>
    </>
  );
}
