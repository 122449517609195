import React, { useContext } from "react";
import Sidebar from "../SideBar/Sidebar";
import { sidebarcontext } from "../../context/Sidebarcontext";
import { Outlet, useLocation } from "react-router-dom";
import Header from "../Header/Header";

export default function Mainlayout() {
  let { isSideOpen } = useContext(sidebarcontext);
  const location = useLocation();
  let path = location.pathname;
  return (
    <>
      <Sidebar />
      <div>
        <Header />
        <div
          className={
            isSideOpen
              ? path === "/" || path === "/dashboard"
                ? "outlet-content-max p-16"
                : "outlet-content-max"
              : path !== "/" && path !== "/dashboard"
              ? "outlet-content-min"
              : "outlet-content-min p-16"
          }
        >
          <Outlet />
        </div>
      </div>
    </>
  );
}
