import React from 'react'
import PopupBodyItem from '../../Helpers/Popup/PopupParts/PopupBodyItem';

export default function DriverDetails() {
 return (
   <>
     <p className="fs-16 fw-700 brand-700 text-uppercase">driver details</p>
     <div className="row">
       <div className="col-lg-6">
         <div className="display-border ">
           <PopupBodyItem title="driver name" item="omar ahmed" />
           <PopupBodyItem title="driver code" item="2460" />
           <PopupBodyItem title="ID number" item="4524544" />
           <PopupBodyItem title="liscense start date" item="11/2/2024" />
           <PopupBodyItem title="liscense end date" item="11/2/2026" />
           <PopupBodyItem title="address 1" item="riydah" />
         </div>
       </div>
       <div className="col-lg-6">
         <div className="display-border ">
           <PopupBodyItem title="phone number" item="+956124589634" />
           <PopupBodyItem title="emergency number" item="+956125487852" />
           <PopupBodyItem title="lisencse number" item="41252153" />
           <PopupBodyItem title="contract start date" item="11/2/2024" />
           <PopupBodyItem title="contract end date" item="11/2/2026" />
         </div>
       </div>
     </div>
   </>
 );
}
