/* eslint-disable react/style-prop-object */
import React, { useContext, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Close from "../CloseBtn/Close";
import VehicleDetails from "../../Vehicles/VehicleInf/VehicleDetails";
import VehicleSensors from "../../Vehicles/VehicleInf/VehicleSensors";
import Popup from "./Popup";
import PopupTitle from "./PopupParts/PopupTitle";
import DriverDetails from "../../Drivers/DriversInf/DriverDetails";
import { popupcontext } from "../../../context/Popupscontext";
import DriverAttachment from "../../Drivers/DriversInf/DriverAttachment";
import { useGetSpecificVehicleQuery } from "../../../Redux/service/Vehicles/Vehicles";
import Loader from "../Loader/Loader";

export function VehiclePopup({ vehicleId, onClose }) {
  const {
    data: vehicleDetails,
    isLoading,
    refetch,
  } = useGetSpecificVehicleQuery(vehicleId);
  let { edited } = useContext(popupcontext);
  useEffect(() => {
    if (edited) {
      refetch();
    }
  }, [edited, refetch, vehicleId]);
  return (
    <Popup show={!!vehicleId}>
      {isLoading && <Loader />}
      <Modal.Header className="modal-header flex-between">
        <Modal.Title id="contained-modal-title-vcenter">
          <PopupTitle
            vehicleimg="Car.svg"
            headtitle={`${vehicleDetails?.name}`}
            subtitle={`${vehicleDetails?.vehicleType}`}
          />
        </Modal.Title>
        <Close style="close-32 close" close={onClose} img="Close" />
      </Modal.Header>
      <Modal.Body className="modal-body-scrollable">
        <VehicleDetails values={vehicleDetails} />
        <VehicleSensors values={vehicleDetails} />
      </Modal.Body>
    </Popup>
  );
}

export function DriverPopup() {
  let { handleCloseDisplay, display } = useContext(popupcontext);
  return (
    <Popup show={display}>
      <Modal.Header className="modal-header flex-between">
        <Modal.Title id="contained-modal-title-vcenter">
          <PopupTitle
            driverimg="hero.jpeg"
            headtitle="omar ahmed"
            subtitle="truck driver"
          />
        </Modal.Title>
        <Close style="close-32 close" close={handleCloseDisplay} img="Close" />
      </Modal.Header>
      <Modal.Body className="modal-body-scrollable">
        <DriverDetails />
        <DriverAttachment />
      </Modal.Body>
    </Popup>
  );
}
