import React from "react";
import { createContext, useState } from "react";
export const popupcontext = createContext();
export default function PopupContext({ children }) {
  //Form
  const [form, setForm] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [edited, setEdited] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [display, setDisplay] = useState(false);
  const [remove, setRemove] = useState(false);
  const [object, setObject] = useState(false);
  const [objectbody, setObjectbody] = useState(true);
  const [objectselect, setObjectselect] = useState(false);
  const [displayGeo, setDisplayGeo] = useState(false);
  const [currentVehicleId, setCurrentVehicleId] = useState(null);
  const [currentDriverId, setCurrentDriverId] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const [attachmentsId, setAttachmentsId] = useState([]);
  const [picture, setPicture] = useState(false);
  const [historyPlayback, setHistoryPlayback] = useState({
    state: false,
    Id: 0,
  });
  const [isPlaying, setIsPlaying] = useState(false);
  const [posIndex, setPosIndex] = useState(0);
  const handleClose = (type) => {
    if (type === "form") {
      setForm(false);
      setTimeout(() => {
        setIsEditing(false);
      }, 300);
      setCurrentDriverId(null);
      setCurrentVehicleId(null);
    }
    if (type === "display") {
      setCurrentVehicleId(null);
      setCurrentDriverId(null);
    }
    if (type === "deleting") {
      setIsDeleting(false);
      setRemove(false);
      setCurrentDriverId(null);
      setCurrentVehicleId(null);
    }
    if (type === "editing") {
      setCurrentDriverId(null);
      setCurrentVehicleId(null);
    }
    if (type === "object") {
      setObjectbody(false);
    }
    if (type === "objectSelected") {
      setObject(false);
      setObjectselect(false);
      setHistoryPlayback(false);
    }
    if (type === "minimizeSelected") {
      setObjectselect(false);
    }
    if (type === "objectUsers" || type === "historyPlayBack") {
      setDisplay(false);
    }
  };
  const handleShow = (name, type, id) => {
    if (name === "addForm") setForm(true);
    if (name === "editForm") handleEditForm(type, id);
    if (name === "deleteItem") handleRemove(type, id);
    if (name === "objectUsers" || name === "historyPlayBack") setDisplay(true);
  };

  const handleEditForm = (name, id) => {
    if (name === "vehicles") {
      setCurrentVehicleId(id);
      setIsEditing(true);
      setForm(true);
    }
    if (name === "drivers") {
      setIsEditing(true);
      setForm(true);
      setCurrentDriverId(id);
    }
  };

  const handleRemove = (name, id) => {
    if (name === "vehicles") setCurrentVehicleId(id);
    if (name === "drivers") setCurrentDriverId(id);
    setIsDeleting(true);
  };

  const handleShowObject = () => setObject(true);

  const handleShowObjectBody = () => setObjectbody(true);

  const handleShowSelected = () => setObjectselect(true);

  const handleShowGeo = () => setDisplayGeo(true);
  const handleCloseGeo = () => setDisplayGeo(false);

  const showDetails = (name, id) => {
    if (name === "vehicles") setCurrentVehicleId(id);
    if (name === "drivers") setCurrentDriverId(id);
  };

  return (
    <>
      <popupcontext.Provider
        value={{
          form,
          setForm,
          handleClose,
          handleShow,
          display,
          setDisplay,
          handleRemove,
          remove,
          object,
          handleShowObject,
          objectbody,
          handleShowObjectBody,
          setObjectbody,
          objectselect,
          setObjectselect,
          handleShowSelected,
          displayGeo,
          handleShowGeo,
          handleCloseGeo,
          currentVehicleId,
          setCurrentVehicleId,
          currentDriverId,
          setCurrentDriverId,
          showDetails,
          setIsDeleting,
          isDeleting,
          deleted,
          setDeleted,
          isEditing,
          setIsEditing,
          edited,
          setEdited,
          handleEditForm,
          attachments,
          setAttachments,
          attachmentsId,
          setAttachmentsId,
          picture,
          setPicture,
          historyPlayback,
          setHistoryPlayback,
          isPlaying,
          setIsPlaying,
          posIndex,
          setPosIndex,
        }}
      >
        {children}
      </popupcontext.Provider>
    </>
  );
}
