import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { PositionsApi } from "./service/Positions";
import { webSocketSlice } from "./service/Websocket";
import { DevicesApi } from "./service/Devices";
import { GroupsApi } from "./service/Groups";
import { UsersApi } from "./service/Users";
import { GeofencesApi } from "./service/Geofences/Geofences";
import { geofencesSlice } from "./service/Geofences/GeofenceSlice";
import { VehicleApi } from "./service/Vehicles/Vehicles";
import { vehiclesSlice } from "./service/Vehicles/VehiclesSlice";
import {thunk} from "redux-thunk";

export const store = configureStore({
  reducer: {
    [PositionsApi.reducerPath]: PositionsApi.reducer,
    [DevicesApi.reducerPath]: DevicesApi.reducer,
    [UsersApi.reducerPath]: UsersApi.reducer,
    [GroupsApi.reducerPath]: GroupsApi.reducer,
    [GeofencesApi.reducerPath]: GeofencesApi.reducer,
    geofences: geofencesSlice.reducer,
    [VehicleApi.reducerPath]: VehicleApi.reducer,
    vehicles: vehiclesSlice.reducer,
    webSocket: webSocketSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(
      PositionsApi.middleware,
      DevicesApi.middleware,
      UsersApi.middleware,
      GroupsApi.middleware,
      GeofencesApi.middleware,
      VehicleApi.middleware,
      thunk
    );
  },
});

setupListeners(store.dispatch);
