import React from "react";

export default function TableHead({ HeadField, style }) {
  let head = HeadField?.map((i, index) => {
    return (
      <div key={index} className="fs-14 fw-400 text-capitalize table-element">
        {i}
      </div>
    );
  });
  return (
    <>
      <div className={`table-head d-flex p-16`}>
        <div className="table-checkbox">
          <input type="checkbox" name="" id="" />
        </div>
        {head}
      </div>
    </>
  );
}
