import React from "react";
import { Input } from "../../../Helpers/Input/Input";

export default function FuelSupport({ addData }) {
  return (
    <>
      <div className="flex-between">
        <div className="vehicle-input">
          <Input
            title="Fuel Capacity"
            type="text"
            placeholder="Fuel Capacity"
            id="fuelCapacity"
            name="fuelCapacity"
            vehicleformstyle="vehicle-form"
            onBlur={addData.handleBlur}
            onChange={addData.handleChange}
            errors={addData.errors.fuelCapacity}
            touched={addData.touched.fuelCapacity}
          />
        </div>
        <div className="vehicle-input">
          <Input
            title="Tank Height"
            type="text"
            placeholder="Tank Height"
            id="tankHeight"
            name="tankHeight"
            vehicleformstyle="vehicle-form"
            onBlur={addData.handleBlur}
            onChange={addData.handleChange}
            errors={addData.errors.tankHeight}
            touched={addData.touched.tankHeight}
          />
        </div>
      </div>

      <div className="flex-between">
        <div className="vehicle-input">
          <Input
            title="Tank Width"
            type="text"
            placeholder="Tank Width"
            id="tankWidth"
            name="tankWidth"
            vehicleformstyle="vehicle-form"
            onBlur={addData.handleBlur}
            onChange={addData.handleChange}
            errors={addData.errors.tankWidth}
            touched={addData.touched.tankWidth}
          />
        </div>
        <div className="vehicle-input">
          <Input
            title="Tank Length"
            type="text"
            placeholder="Tank Length"
            id="tankLength"
            name="tankLength"
            vehicleformstyle="vehicle-form"
            onBlur={addData.handleBlur}
            onChange={addData.handleChange}
            errors={addData.errors.tankLength}
            touched={addData.touched.tankLength}
          />
        </div>
      </div>
    </>
  );
}
