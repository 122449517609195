import React, { useContext } from "react";
import Delete from "../Delete/DeletePopup";
import { popupcontext } from "../../../context/Popupscontext";
import Display from "./Table Actions/Display";

export default function TableActions({ vehicleId }) {
  const { handleRemove, handleCloseRemove, showDetails, handleEditForm } =
    useContext(popupcontext);

  const handleDeleteClick = () => {
    handleRemove(vehicleId); // Pass the vehicleId to handleRemove
  };

  const handleDisplayClick = () => {
    showDetails(vehicleId); // Display vehicle details
  };

  const handleEdit = () => {
    handleEditForm(vehicleId);
  };
  return (
    <div className="table-element y-25">
      <div className="d-flex table-actions">
        <Display vehicleId={vehicleId} />
        <img
          role="button"
          src="/assets/Edit.svg"
          alt="edit"
          onClick={handleEdit}
        />

        <Delete
          vehicleId={vehicleId}
          onClick={handleDeleteClick}
          onHide={handleCloseRemove}
        />
      </div>
    </div>
  );
}
