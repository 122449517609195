import { createSlice } from "@reduxjs/toolkit";

export const vehiclesSlice = createSlice({
  name: "vehicles",
  initialState: {
    vehicles: [],
  },
  reducers: {
    addVehicles: (state, action) => {
      state.vehicles = action.payload;
    },
  },
});

export const { addVehicles } = vehiclesSlice.actions;
export default vehiclesSlice.reducer;
