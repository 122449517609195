import React, { useContext } from "react";
import ObjectSelectedHead from "./SelectedHead/ObjectSelectedHead";
import { popupcontext } from "../../../context/Popupscontext";
import ObjectMinimized from "../Object/ObjectMinimized/ObjectMinimized";
import ObjectSelectedTabs from "./SelectedBody/ObjectSelectedTabs";
import { useGetSpecificVehicleImeiQuery } from "../../../Redux/service/Vehicles/Vehicles";

export default function ObjectSelected({ imei, device, position }) {
  let { objectselect, handleShowSelected } = useContext(popupcontext);
  let { data: Vehicles } = useGetSpecificVehicleImeiQuery(imei);
  console.log("Device", device);
  return (
    <>
      {objectselect ? (
        <div className="bg-white-rounded p-0 object-selected">
          <div className="streetview">
            <ObjectSelectedHead values={Vehicles} />
          </div>

          <ObjectSelectedTabs
            title="vehicle details"
            img="Cardetails"
            values={Vehicles}
          />
          <ObjectSelectedTabs
            title="working status"
            img="Carbattery"
            values={Vehicles}
            device={device}
            position={position}
          />
          <ObjectSelectedTabs
            title="sensors"
            img="Sensors"
            position={position}
          />
          <ObjectSelectedTabs title="controls" img="Control" />
          <ObjectSelectedTabs title="camera" img="Camera" />
        </div>
      ) : objectselect === false ? (
        <ObjectMinimized title="vehicle" minimize={handleShowSelected} />
      ) : (
        ""
      )}
    </>
  );
}
