/* eslint-disable react/style-prop-object */
import React, { useContext } from "react";
import Close from "../../../Helpers/CloseBtn/Close";
import { popupcontext } from "../../../../context/Popupscontext";

export default function GeofenceHead() {
  let { handleCloseGeo } = useContext(popupcontext);

  return (
    <div className="flex-between p-16">
      <div className="flex-between w-50">
        <img src="assets/Geofence.svg" alt="Geofence" />
        <div className="fs-18 fw-600 text-capitalize">add new geofence</div>
      </div>
      <Close style="close close-btn" img="Close" close={handleCloseGeo} />
    </div>
  );
}
