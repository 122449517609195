/* eslint-disable react/style-prop-object */
import React from "react";
import TableHead from "./TableHead";
import TableBody from "./TableBody";

export default function Table({ HeadField, BodyData , children }) {
  console.log("Table v" ,BodyData)
  return (
    <>
      <div className="bg-white-rounded-top mt-3 table-parent">
        <div className="table">
          <TableHead HeadField={HeadField} style="p-16" />
          <TableBody BodyData={BodyData} />
          {children}
        </div>
      </div>
    </>
  );
}
