import React from "react";

export function Input({
  title,
  type,
  placeholder,
  onBlur,
  onChange,
  id,
  name,
  vehicleformstyle,
  value,
  errors,
  touched,
  disabled,
}) {
  const FormError = errors && touched ? "form-error" : "";

  return (
    <>
      <div className={`position-relative ${vehicleformstyle} ${FormError}`}>
        <label className="form-label">{title}</label>
        <input
          className={`form-control`}
          type={type}
          placeholder={placeholder}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          id={id}
          name={name}
          disabled={disabled}
        />
        <div>
          {errors && touched ? <div className="validation">{errors}</div> : ""}
        </div>
      </div>
    </>
  );
}

export function InputVehicleGroup({
  id,
  name,
  onChange,
  onBlur,
  errors,
  touched,
  vehicleformstyle,
  value,
}) {
  const FormError = errors && touched ? "form-error" : "";

  return (
    <>
      <div className={`position-relative ${vehicleformstyle} ${FormError} `}>
        <label className="form-label">Vehicle type</label>
        <select
          id={id}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          value={value || ""}
          className="form-select"
        >
          <option value="" disabled>
            Select Vehicle Type
          </option>
          <optgroup className="input-group" label="Commercial Vehicles">
            <option value="Delivery Trucks">Delivery Trucks</option>
            <option value="Cargo Vans">Cargo Vans</option>
            <option value="Utility Trucks">Utility Trucks</option>
          </optgroup>
          <optgroup label="Passenger Vehicles">
            <option value="Sedans">Sedans</option>
            <option value="Suvs">Suvs</option>
            <option value="Vans">Vans</option>
          </optgroup>
          <optgroup label="Specialized Vehicles">
            <option value="Ambulances">Ambulances</option>
            <option value="Police Cars">Police Cars</option>
            <option value="Fire Trucks">Fire Trucks</option>
            <option value="Construction Vehicles">Construction Vehicles</option>
          </optgroup>
          <optgroup label="Public Transportation Vehicles">
            <option value="Buses">Buses</option>
            <option value="Shuttles">Shuttles</option>
          </optgroup>
          <optgroup label="Heavy-Duty Vehicles">
            <option value="Trucks">Trucks</option>
            <option value="Trailers">Trailers</option>
          </optgroup>
          <optgroup label="Special Purpose Vehicles">
            <option value="Refrigerated Trucks">Refrigerated Trucks</option>
            <option value="Tanker Trucks">Tanker Trucks</option>
            <option value="Tow Trucks">Tow Trucks</option>
          </optgroup>
          <optgroup label="Construction Off-Road Vehicles">
            <option value="Excavators">Excavators</option>
            <option value="Bulldozers">Bulldozers</option>
            <option value="Off-Road Vehicles">Off-Road Vehicles</option>
          </optgroup>
          <optgroup label="Electric Vehicles (EVs)">
            <option value="Electric Cars">Electric Cars</option>
            <option value="Electric Trucks">Electric Trucks</option>
            <option value="Electric Vans">Electric Vans</option>
          </optgroup>
        </select>
        {errors && touched ? <div className="validation">{errors}</div> : ""}
      </div>
    </>
  );
}

export function InputCountriesGroup({
  id,
  name,
  onChange,
  onBlur,
  errors,
  touched,
  vehicleformstyle,
  value,
}) {
  const FormError = errors && touched ? "form-error" : "";

  return (
    <>
      <div className={`position-relative ${vehicleformstyle} ${FormError} `}>
        <label className="form-label">Manfacture country</label>
        <select
          id={id}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          value={value || ""}
          className="form-select"
        >
          <option value="" disabled>
            {" "}
            Select country
          </option>
          <option value="afghanistan">Afghanistan</option>
          <option value="albania">Albania</option>
          <option value="algeria">Algeria</option>
          <option value="america">America</option>
          <option value="angola">Angola</option>
          <option value="anguilla">Anguilla</option>
          <option value="antigua-and-barbuda">Antigua and Barbuda</option>
          <option value="argentina">Argentina</option>
          <option value="armenia">Armenia</option>
          <option value="australia">Australia</option>
          <option value="azerbaijan">Azerbaijan</option>
          <option value="bahamas">Bahamas</option>
          <option value="bangladesh">Bangladesh</option>
          <option value="barbados">Barbados</option>
          <option value="belarus">Belarus</option>
          <option value="belize">Belize</option>
          <option value="benin">Benin</option>
          <option value="bermuda">Bermuda</option>
          <option value="bhutan">Bhutan</option>
          <option value="bolivia">Bolivia</option>
          <option value="bosnia-and-herzegovina">Bosnia and Herzegovina</option>
          <option value="botswana">Botswana</option>
          <option value="brazil">Brazil</option>
          <option value="british-virgin-islands">British Virgin Islands</option>
          <option value="brunei">Brunei</option>
          <option value="burkina-faso">Burkina Faso</option>
          <option value="burundi">Burundi</option>
          <option value="cambodia">Cambodia</option>
          <option value="cameroon">Cameroon</option>
          <option value="canada">Canada</option>
          <option value="cape-verde">Cape Verde</option>
          <option value="cayman-islands">Cayman Islands</option>
          <option value="central-african-republic">
            Central African Republic
          </option>
          <option value="chad">Chad</option>
          <option value="chile">Chile</option>
          <option value="china">China</option>
          <option value="colombia">Colombia</option>
          <option value="comoros">Comoros</option>
          <option value="cook-islands">Cook Islands</option>
          <option value="costa-rica">Costa Rica</option>
          <option value="cote-divoire">Cote d'Ivoire</option>
          <option value="cuba">Cuba</option>
          <option value="democratic-republic-of-the-congo">
            Democratic Republic of the Congo
          </option>
          <option value="djibouti">Djibouti</option>
          <option value="dominica">Dominica</option>
          <option value="dominican-republic">Dominican Republic</option>
          <option value="ecuador">Ecuador</option>
          <option value="egypt">Egypt</option>
          <option value="el-salvador">El Salvador</option>
          <option value="equatorial-guinea">Equatorial Guinea</option>
          <option value="eritrea">Eritrea</option>
          <option value="eswatini">Eswatini</option>
          <option value="ethiopia">Ethiopia</option>
          <option value="falkland-islands">Falkland Islands</option>
          <option value="fiji">Fiji</option>
          <option value="gabon">Gabon</option>
          <option value="gambia">Gambia</option>
          <option value="georgia">Georgia</option>
          <option value="ghana">Ghana</option>
          <option value="greece">Greece</option>
          <option value="grenada">Grenada</option>
          <option value="guatemala">Guatemala</option>
          <option value="guinea">Guinea</option>
          <option value="guinea-bissau">Guinea Bissau</option>
          <option value="guyana">Guyana</option>
          <option value="haiti">Haiti</option>
          <option value="honduras">Honduras</option>
          <option value="hong-kong">Hong Kong</option>
          <option value="iceland">Iceland</option>
          <option value="india">India</option>
          <option value="indonesia">Indonesia</option>
          <option value="iran">Iran</option>
          <option value="iraq">Iraq</option>
          <option value="italy">Italy</option>
          <option value="jamaica">Jamaica</option>
          <option value="japan">Japan</option>
          <option value="jordan">Jordan</option>
          <option value="kazakhstan">Kazakhstan</option>
          <option value="kenya">Kenya</option>
          <option value="kiribati">Kiribati</option>
          <option value="kosovo">Kosovo</option>
          <option value="kyrgyzstan">Kyrgyzstan</option>
          <option value="laos">Laos</option>
          <option value="lebanon">Lebanon</option>
          <option value="lesotho">Lesotho</option>
          <option value="liberia">Liberia</option>
          <option value="libya">Libya</option>
          <option value="madagascar">Madagascar</option>
          <option value="malawi">Malawi</option>
          <option value="malaysia">Malaysia</option>
          <option value="maldives">Maldives</option>
          <option value="mali">Mali</option>
          <option value="marshall-islands">Marshall Islands</option>
          <option value="mauritania">Mauritania</option>
          <option value="mauritius">Mauritius</option>
          <option value="mexico">Mexico</option>
          <option value="micronesia">Micronesia</option>
          <option value="moldova">Moldova</option>
          <option value="mongolia">Mongolia</option>
          <option value="montenegro">Montenegro</option>
          <option value="montserrat">Montserrat</option>
          <option value="morocco">Morocco</option>
          <option value="mozambique">Mozambique</option>
          <option value="myanmar">Myanmar</option>
          <option value="namibia">Namibia</option>
          <option value="nauru">Nauru</option>
          <option value="nepal">Nepal</option>
          <option value="new-zealand">New Zealand</option>
          <option value="nicaragua">Nicaragua</option>
          <option value="niger">Niger</option>
          <option value="nigeria">Nigeria</option>
          <option value="north-macedonia">North Macedonia</option>
          <option value="occupied-palestinian-territories">
            Occupied Palestinian Territories
          </option>
          <option value="pakistan">Pakistan</option>
          <option value="palau">Palau</option>
          <option value="panama">Panama</option>
          <option value="papua-new-guinea">Papua New Guinea</option>
          <option value="paraguay">Paraguay</option>
          <option value="peru">Peru</option>
          <option value="philippines">Philippines</option>
          <option value="republic-of-congo">Republic of Congo</option>
          <option value="russia">Russia</option>
          <option value="rwanda">Rwanda</option>
          <option value="saint-helena-ascension-tristan-da-cunha">
            Saint Helena; Ascension; and Tristan da Cunha
          </option>
          <option value="saint-kitts-and-nevis">Saint Kitts and Nevis</option>
          <option value="saint-lucia">Saint Lucia</option>
          <option value="saint-vincent-and-the-grenadines">
            Saint Vincent and the Grenadines
          </option>
          <option value="samoa">Samoa</option>
          <option value="sao-tome-and-principe">Sao Tome and Principe</option>
          <option value="senegal">Senegal</option>
          <option value="serbia">Serbia</option>
          <option value="seychelles">Seychelles</option>
          <option value="sierra-leone">Sierra Leone</option>
          <option value="singapore">Singapore</option>
          <option value="solomon-islands">Solomon Islands</option>
          <option value="somalia">Somalia</option>
          <option value="somaliland">Somaliland</option>
          <option value="south-africa">South Africa</option>
          <option value="south-caucasus">South Caucasus</option>
          <option value="south-korea">South Korea</option>
          <option value="south-sudan">South Sudan</option>
          <option value="sri-lanka">Sri Lanka</option>
          <option value="sudan">Sudan</option>
          <option value="suriname">Suriname</option>
          <option value="sweden">Sweden</option>
          <option value="syria">Syria</option>
          <option value="taiwan">Taiwan</option>
          <option value="tajikistan">Tajikistan</option>
          <option value="tanzania">Tanzania</option>
          <option value="thailand">Thailand</option>
          <option value="timor-leste">Timor-Leste</option>
          <option value="togo">Togo</option>
          <option value="tonga">Tonga</option>
          <option value="trinidad-and-tobago">Trinidad and Tobago</option>
          <option value="tunisia">Tunisia</option>
          <option value="turkey">Turkey</option>
          <option value="turkmenistan">Turkmenistan</option>
          <option value="turks-and-caicos-islands">
            Turks and Caicos Islands
          </option>
          <option value="tuvalu">Tuvalu</option>
          <option value="uganda">Uganda</option>
          <option value="ukraine">Ukraine</option>
          <option value="uruguay">Uruguay</option>
          <option value="uzbekistan">Uzbekistan</option>
          <option value="vanuatu">Vanuatu</option>
          <option value="venezuela">Venezuela</option>
          <option value="vietnam">Vietnam</option>
          <option value="yemen">Yemen</option>
          <option value="zambia">Zambia</option>
          <option value="zimbabwe">Zimbabwe</option>
        </select>
        {errors && touched ? <div className="validation">{errors}</div> : ""}
      </div>
    </>
  );
}

export function InputFuelGroup({
  id,
  name,
  onChange,
  onBlur,
  errors,
  touched,
  vehicleformstyle,
  value,
}) {
  const FormError = errors && touched ? "form-error" : "";

  return (
    <>
      <div className={`position-relative ${vehicleformstyle} ${FormError} `}>
        <label className="form-label">Fuel Type</label>
        <select
          className="form-select"
          id={id}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          value={value || ""}
        >
          <option value="" disabled>
            Select Fuel Type
          </option>
          <option value="Gas">Gas</option>
          <option value="Diesel">Diesel</option>
          <option value="Sollar">Sollar</option>
        </select>
        {errors && touched ? <div className="validation">{errors}</div> : ""}
      </div>
    </>
  );
}

export function InputParentGroup({
  id,
  name,
  onChange,
  onBlur,
  errors,
  touched,
  value,
  vehicleformstyle,
}) {
  const FormError = errors && touched ? "form-error" : "";
  return (
    <>
      <div className={`position-relative ${vehicleformstyle} ${FormError} `}>
        <label className="form-label">Vehicle related to</label>
        <select
          id={id}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          value={value || ""}
          className="form-select"
        >
          <option value="" disabled>
            Select Parent
          </option>
          <option value="1">Demo 1</option>
          <option value="2">Demo 2</option>
          <option value="3">Demo 3</option>
        </select>
        {errors && touched ? <div className="validation">{errors}</div> : ""}
      </div>
    </>
  );
}

export function Switch({
  id,
  name,
  onBlur,
  onChange,
  value,
  vehicleformstyle,
  vehicleInf,
}) {
  return (
    <>
      <label className={`switch`}>
        <input
          className="form-control"
          type="checkbox"
          id={id}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          checked={value ? true : false}
          autoFocus
        />
        <span className="slider round"></span>
      </label>
    </>
  );
}

export function Sensors({
  title,
  vehicleInf,
  id,
  name,
  onBlur,
  onChange,
  value,
}) {
  return (
    <>
      <div className={`sensors mb-16`}>
        <div className="flex-between mb-8">
          <div className="fs-16 fw-500 neutral-500 text-capitalize">
            {vehicleInf ? `${title} support` : `${title}  sensor support`}
          </div>
          <div className="y-25">
            <Switch
              id={id}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
            />
          </div>
        </div>
        <div className={vehicleInf ? "" : "H-line-2"}></div>
      </div>
    </>
  );
}
