import React from "react";
import ObjectSelectedOptions from "./ObjectSelectedOptions";

export default function ObjectSelectedControls() {
  return (
    <>
      <div>
        <ObjectSelectedOptions
          option1="start engine"
          option2="sensor settings"
        />
      </div>
      <div>
        <ObjectSelectedOptions
          option1="set speed limit"
          option2="set geofence"
        />
      </div>
      <div>
        <ObjectSelectedOptions
          option1="set custom alarm"
          option2="share location"
        />
      </div>
      <div>
        <ObjectSelectedOptions
          option1="set marker (POI)"
          option2="open sessions"
        />
      </div>
    </>
  );
}
