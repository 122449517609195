import React from "react";
import ObjectSelectedSensorsRows from "./ObjectSelectedSensorsRows";

export default function ObjectSelectedSensors({ position }) {
  return (
    <>
      <div className="object-selected-sensors">
        <ObjectSelectedSensorsRows
          title="speed"
          val={`${Math.round(position?.speed)} km/h`}
          img="baselinespeed"
          position={position}
        />
        <ObjectSelectedSensorsRows title="fuel" val="N/A" img="Fuel" />
        <ObjectSelectedSensorsRows
          title="temperature"
          val="N/A"
          img="Temperature"
        />
        <ObjectSelectedSensorsRows title="ignition" val="OFF" img="Ignition" />
        <ObjectSelectedSensorsRows title="door" val="Closed" img="Door" />
      </div>
    </>
  );
}
