import React, { useState } from "react";
import ObjectSelectedDetails from "../SelectedDetails/ObjectSelectedDetails";
import ObjectSelectedStatus from "../SelectedStatus/ObjectSelectedStatus";
import ObjectSelectedSensors from "../SelectedSensors/ObjectSelectedSensors";
import ObjectSelectedControls from "../SelectedControls/ObjectSelectedControls";
import ObjectSelectedCamera from "../SelectedCamera/ObjectSelectedCamera";

export default function ObjectSelectedTabs({
  title,
  img,
  values,
  device,
  position,
}) {
  const [openDropdown, setOpenDropdown] = useState(null);

  const toggleDropdown = (name) => {
    setOpenDropdown(openDropdown === name ? null : name);
  };
  return (
    <>
      <div className="object-body-title" onClick={() => toggleDropdown(title)}>
        <div className="flex-between">
          <div className="mr-16">
            <img src={`/assets/${img}.svg`} alt="" />
          </div>
          <div className="fs-16 fw-600" role="button">
            {title}
          </div>
        </div>
        <div>
          <img
            role="button"
            src={
              openDropdown === title
                ? "/assets/Uparrow.svg"
                : "/assets/Downarrow.svg"
            }
            alt="arrow"
          />
        </div>
      </div>
      {openDropdown === "vehicle details" ? (
        <ObjectSelectedDetails details={values} />
      ) : (
        ""
      )}
      {openDropdown === "working status" ? (
        <ObjectSelectedStatus
          details={values}
          device={device}
          position={position}
        />
      ) : (
        ""
      )}
      {openDropdown === "sensors" ? (
        <ObjectSelectedSensors position={position} />
      ) : (
        ""
      )}
      {openDropdown === "controls" ? <ObjectSelectedControls /> : ""}
      {openDropdown === "camera" ? <ObjectSelectedCamera /> : ""}
    </>
  );
}
