/* eslint-disable react/style-prop-object */
import React, { useContext, useEffect, useState } from "react";
import TableActions from "../../Helpers/Table/TableActions";
import { useGetAllDevicesQuery } from "../../../Redux/service/Devices";
import TableRow from "../../Helpers/Table/TableRow";
import { popupcontext } from "../../../context/Popupscontext";

export default function VehiclesTableBody({ data }) {
  const {
    data: Devices,
    refetch: refetchDevices,
    isLoading: devicesLoading,
  } = useGetAllDevicesQuery();
  const [updatedData, setUpdatedData] = useState([]);
  const { deleted, setDeleted, isEditing, edited, setEdited } =
    useContext(popupcontext);
  useEffect(() => {
    refetchDevices();
  }, [data, refetchDevices]);

  useEffect(() => {
    if (deleted) {
      refetchDevices();
      setDeleted(false);
    }
  }, [deleted, refetchDevices, setDeleted, isEditing, edited, setEdited]);

  useEffect(() => {
    if (!devicesLoading && Devices && data) {
      const combinedData = data.map((i) => {
        const matchingDevice = Devices?.find(
          (device) => device.uniqueId === i.imei
        );
        return { ...i, matchingDevice };
      });
      setUpdatedData(combinedData);
    }
  }, [data, Devices, devicesLoading]);
  return (
    <>
      {updatedData.map((i) => {
        if (i.matchingDevice) {
          return (
            <div key={i.id} className="d-flex p-16">
              <div className="table-checkbox">
                <input type="checkbox" name="" id="" />
              </div>
              <TableRow value={i.name} style="table-element-name" />
              <TableRow value={i.plateNumber} />
              <TableRow
                value={i.matchingDevice.status}
                style={
                  i.matchingDevice.status === "offline" ||
                  i.matchingDevice.status === "unknown"
                    ? "table-element-fail"
                    : "table-element-success"
                }
              />
              <TableRow value={i.imei} />
              <TableRow
                value={
                  i.matchingDevice.lastUpdate === null ? "N/A" : i.lastUpdate
                }
              />
              <TableRow
                value={i.archived === false ? "Active" : "Archived"}
                style={
                  i.archived === false
                    ? "table-element-active fw-500"
                    : "table-element-archived fw-500"
                }
              />
              <TableActions vehicleId={i.id} />
            </div>
          );
        }

        return null;
      })}
    </>
  );
}
