import React from 'react'
import ProfilePicture from './../../Helpers/UploadPicture/PorfilePicture'
import DriverInfo from './DriverInfo';
import DriverDateInfo from './DriverDateInfo';
import Dropfile from '../../Helpers/Dropzone/Dropzone';
import OffcanvasFooter from '../../Helpers/Offcanvasfooter/OffcanvasFooter';
export default function DriverBody() {
  return (
    <>
      <ProfilePicture />
      <DriverInfo />
      <DriverDateInfo />
      <Dropfile />
      <OffcanvasFooter />
    </>
  );
}
