import React from "react";
import ObjectSelectedRows from "../SelectedBody/ObjectSelectedRows";

export default function ObjectSelectedDetails({ details }) {
  return (
    <>
      <div className="object-selected-details">
        <div className="flex-center mb-2">
          <img src="/assets/Car.svg" alt="" />
          <div className="ms-3">
            <div className="fs-16 fw-600 neutral-700">{details?.name}</div>
            <div className="fs-14 fw-400 neutral-500">{details?.brand}</div>
          </div>
        </div>
        <ObjectSelectedRows title="car number" item={details?.id} />
        <ObjectSelectedRows title="sim number" item={details?.simNumber} />
        <ObjectSelectedRows title="device ID" item={details?.imei} />
        <ObjectSelectedRows
          title="car activity"
          item={details?.archived ? "Active" : "Archived"}
        />
        <ObjectSelectedRows title="related user" item={details?.parent} />
        <ObjectSelectedRows
          title="assigned driver"
          item={details?.driver?.name ? details?.driver?.name : "Assign Driver"}
        />
      </div>
    </>
  );
}
