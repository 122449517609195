import React, { useContext, useState } from "react";
import GeofenceHead from "./GeofenceHead/GeofenceHead";
import GeofenceBody from "./GeofenceBody/GeofenceBody";
import GeofenceFooter from "./GeofenceFooter/GeofenceFooter";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAddGeofencesMutation } from "../../../Redux/service/Geofences/Geofences";
import { useDispatch } from "react-redux";
import { popupcontext } from "../../../context/Popupscontext";
import { addGeofence } from "../../../Redux/service/Geofences/GeofenceSlice";
import { toast } from "react-toastify";

export default function GeofenceForm({ area }) {
  const [submitAttempt, setSubmitAttempt] = useState(false);
  const [addGeofences, { isSuccess }] = useAddGeofencesMutation();
  const dispatch = useDispatch();

  let { handleCloseGeo } = useContext(popupcontext);

  const handleAddGeo = async (val) => {
    try {
      const addedGeofence = await addGeofences(val)
        .unwrap()
        .catch((err) => console.log(err));
      toast.success("New Geofence Is Added", { autoClose: 800 });
      dispatch(addGeofence(addedGeofence));
    } catch (error) {
      toast.error(`${error?.data?.message}`);
    }
  };

  if (isSuccess) handleCloseGeo();

  let validSchema = Yup.object({
    name: Yup.string().required("Name Is Required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      area: area,
    },
    validationSchema: validSchema,
    onSubmit: async (val) => {
      setSubmitAttempt(true);
      handleAddGeo(val);
    },
  });
  const handleSubmit = (e) => {
    setSubmitAttempt(true);
    formik.handleSubmit(e);
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="Geofence-Form">
        <GeofenceHead />
        <div className="H-line"></div>
        <GeofenceBody AddGeo={formik} />
        <div className="H-line"></div>
        <GeofenceFooter AddGeo={formik} submitAttempt={submitAttempt} />
      </div>
    </form>
  );
}
