import React from "react";
import Modal from "react-bootstrap/Modal";

export default function Popup({ show, children }) {
  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="popup"
        show={show}
      >
        {children}
      </Modal>
    </>
  );
}
