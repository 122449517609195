import React, { useEffect } from "react";
import { MapContainer, TileLayer, Marker, useMap } from "react-leaflet";
import { Icon } from "leaflet";

function ResizeMap({ position }) {
  const map = useMap();

  useEffect(() => {
    if (position?.latitude && position?.longitude) {
      setTimeout(() => {
        map.invalidateSize();
        map.setView([position.latitude, position.longitude], 15);
      }, 400);
    }
  }, [map, position]);

  return null;
}

export default function ObjectSelectedMap({ position }) {
  const customIcon = new Icon({
    iconUrl: "https://www.svgrepo.com/show/513317/location-pin.svg",
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
  });

  // Default fallback coordinates (can be updated if needed)
  const defaultPosition = { latitude: 24.774265, longitude: 46.738586 };

  // Use fallback if position is undefined
  const mapPosition = position?.latitude && position?.longitude ? position : defaultPosition;

  return (
    <div className="s">
      <MapContainer
        id="map-container"
        center={[mapPosition.latitude, mapPosition.longitude]} // Use the fallback or actual position
        zoomControl={false}
        zoom={15}
        scrollWheelZoom={true}
        minZoom={5}
        maxZoom={18}
        zoomAnimation={true}
        zoomSnap={0.1}
        dragging={true}
        className="map-container"
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}&s=Ga"
        />
        <Marker
          position={[mapPosition.latitude, mapPosition.longitude]}
          icon={customIcon}
        />
        <ResizeMap position={mapPosition} />
      </MapContainer>
    </div>
  );
}
