import React from 'react'
import { Input, InputParentGroup } from '../../Helpers/Input/Input';

export default function DriverInfo() {
   return (
     <div className="driverinfo mb-24">
       <p className="fs-16 fw-700 brand-700 text-uppercase">driver info</p>
       <div className="row">
         <div className="col-lg-6">
           <Input title="Name" type="text" placeholder="Driver name" />
           <Input title="ID number" type="text" placeholder="0123456789" />
           <Input title="Phone number 1" type="text" placeholder="Personal number" />
           <Input title="Liscence Nnumber" type="text" placeholder="abc - 123" />
         </div>
         <div className="col-lg-6">
           <Input
             title="Code"
             type="text"
             placeholder="License plate"
           />
           <InputParentGroup title="Parent" type="text" placeholder="Code" />
           <Input
             title="Phone number 2"
             type="text"
             placeholder="Emergency Number"
           />
           <Input title="Address" type="text" placeholder="riydah - saudiarabia" />
         </div>
       </div>
     </div>
   );
}
