import React from "react";

export default function ObjectTableHead() {
  return (
    <> 
      <div className="object-table">
        <div className="table-checkbox">
          <input type="checkbox" name="" id="" />
        </div>
        <div className="fs-14 fw-600 object-table-element">Object Name</div>
        <div className="fs-14 fw-600 object-table-element">Speed</div>
        <div className="fs-14 fw-600 object-table-element">Last Update</div>
        <div className="fs-14 fw-600 object-table-element">Status</div>
      </div>
    </>
  );
}
