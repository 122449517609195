import React, { useContext } from "react";
import { popupcontext } from "../../../context/Popupscontext";
import ObjectDetails from "./ObjectDetails/ObjectDetails";
import ObjectMinimized from "./ObjectMinimized/ObjectMinimized";

export default function Object() {
  let { objectbody, handleShowObjectBody } = useContext(popupcontext);

  return (
    <>
        {objectbody ? (
          <ObjectDetails />
        ) : (
          <ObjectMinimized title="object" minimize={handleShowObjectBody} />
        )}
    </>
  );
}
