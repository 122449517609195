import React from "react";

export default function DropMenu({
  title,
  img,
  children,
  openDropdown,
  toggleDropdown,
}) {
  const dropdownContentClass = (dropdown) =>
    openDropdown === dropdown
      ? "dropdown-content show container"
      : "dropdown-content hide";

  return (
    <>
      <div
        role="button"
        className={`select-item flex-center`}
        onClick={() => toggleDropdown(title)}
      >
        <div className="select-icons">
          <img className="icons" src={`/assets/dark/${img}.svg`} alt={title} />
        </div>
        <p className="select-text">{title}</p>
        <div className="arrow">
          <img
            src={
              openDropdown === title
                ? "/assets/dark/uparrow.svg"
                : "/assets/dark/downarrow.svg"
            }
            alt="arrow"
          />
        </div>
      </div>
      {openDropdown === title && (
        <div className={dropdownContentClass(title)}>{children}</div>
      )}
    </>
  );
}
