import React from "react";
import PositionsPopupsDetails from "./PositionsPopupsDetails";

export default  function PositionsPopups({ mark, lat, lon, devicestatus }) {
  return (
    <>
      <div className="device-popup">
        <PositionsPopupsDetails title="fix time" item={mark.fixTime} />
        <PositionsPopupsDetails
          title="speed"
          item={devicestatus ? mark.speed.toFixed(2) + ` KM` : 0}
        />
        <PositionsPopupsDetails title="motion" item={mark.attributes.motion ? "True" : "False"} />
        <PositionsPopupsDetails
          title="total distance"
          item={Math.round(mark.attributes.totalDistance) + ` KM`}
        />
      </div>
    </>
  );
}