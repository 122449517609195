import React from "react";
import VehiclesTableBody from "../../Vehicles/VehiclesTableBody/VehiclesTableBody";

export default function TableBody({ BodyData }) {

  const isVehicleData = BodyData?.some((i) => i?.plateNumber);

  if (isVehicleData) {
    return <VehiclesTableBody data={BodyData} />;
  }

  return null;
}
