import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const PositionsApi = createApi({
  reducerPath: "Positions",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://test.passenger-mea.com/api",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllPositions: builder.query({
      query: () => "/positions",
    }),
    getDevicePosition: builder.query({
      query: (deviceId) => `/positions?deviceId=${deviceId}`,
    }),
  }),
});

export const { useGetAllPositionsQuery, useGetDevicePositionQuery } =
  PositionsApi;
